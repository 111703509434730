















































import Vue from "vue";
import UserHeaderNavigation from "@/components/UserHeaderNavigation.vue";
import { formatDateByToday } from "@/utils/dateUtils";
import InfiniteLoading from "vue-infinite-loading";
import MentorRepository from "@/repositories/mentor-repository";
import MessageThread from "@/models/message-thread";
import UserRepository from "@/repositories/user-repository";
import { auth } from "@/utils/firebase";
import Mentor from "@/models/mentor";

export default Vue.extend({
  name: "UserThread",
  components: {
    InfiniteLoading,
    UserHeaderNavigation,
  },
  data(): { mentors: Mentor[]; threads: MessageThread[]; [key: string]: any } {
    return {
      threads: [],
      mentors: [],
      isLoading: true,
    };
  },
  async mounted() {
    // メンターを取得
    await this.loadThreads();
    await this.loadMentors();
    this.isLoading = false;
  },
  methods: {
    async loadThreads() {
      // メッセージスレッドの読み込み
      const userThreadsQS = await UserRepository.getMessageThreads(
        auth.currentUser!.uid
      );

      this.threads = userThreadsQS.docs.map((dqs) => new MessageThread(dqs));
    },
    async loadMentors() {
      // スレッドに含まれるメンター情報を取得
      if (this.threads.length === 0) {
        // スレッドがない場合はスキップ
        return;
      }
      const mentorIds = this.threads.map((thread) => thread.mentorId);
      const qs = await MentorRepository.getByIds(mentorIds);
      this.mentors = qs.docs.map((doc) => new Mentor(doc));
    },
    async openThread(mentorId: string) {
      this.$router.push({
        name: "user-message",
        params: { mentorId },
      });
    },
    formatDate(date: any) {
      return formatDateByToday(date);
    },

    getMentorById(id: string) {
      return this.mentors.find((mentor) => mentor.id === id) || new Mentor();
    },
  },
});
