























































































import Vue from "vue";
import { auth, serverTimestamp } from "../utils/firebase";
import MentorSignOut from "@/components/MentorSignOut.vue";
import ImageInput from "@/components/form/fields/imageInput.vue";
import TextInput from "@/components/form/fields/textInput.vue";
import SelectInput from "@/components/form/fields/selectInput.vue";
import YearSelect from "@/components/form/fields/YearSelect.vue";
import PrefectureInput from "@/components/form/fields/PrefectureInput.vue";
import UniversityInput from "@/components/form/fields/UniversityInput.vue";
import ProfileInput from "@/components/form/fields/ProfileInput.vue";
import SaveButtonBlue from "@/components/SaveButtonBlue.vue";
import EmailInput from "@/components/form/fields/EmailInput.vue";
import MentorRepository from "@/repositories/mentor-repository";
import Mentor from "@/models/mentor";
import selectOptionsType from "@/types/select-options";
import sexOptions from "@/constants/sex-options";
import StorageHandler from "@/utils/storage";

export default Vue.extend({
  components: {
    MentorSignOut,
    SaveButtonBlue,
    ImageInput,
    TextInput,
    SelectInput,
    YearSelect,
    UniversityInput,
    EmailInput,
    ProfileInput,
    PrefectureInput,
  },
  data(): {
    mentor: Mentor;
    isUpdating: boolean;
    // 拡張子エラーメッセ
    wrongFileTypeMessage: string;
    noFileMessage: string;
    isUpdateFailed: boolean;
    sexOptions: selectOptionsType;
    isLoadingCompleted: boolean;
    file: any;
  } {
    return {
      mentor: new Mentor(),
      isUpdating: false,
      // 拡張子エラーメッセ
      wrongFileTypeMessage: "",
      noFileMessage: "",
      isUpdateFailed: false,
      // 配列を選択肢の型に変換
      sexOptions: sexOptions.map((o) => ({ text: o, value: o })),
      isLoadingCompleted: false,
      file: null,
    };
  },
  async mounted() {
    await this.loadProfile();
    if (this.mentor.isReviewed) {
      // 登録済みの場合はhomeに飛ばす
      this.$router.push({ name: "mentor-home" });
      return;
    }
    this.isLoadingCompleted = true;
  },
  methods: {
    async loadProfile() {
      const mentorId = auth.currentUser!.uid;

      // Userとユーザー情報を取得
      const dqs = await MentorRepository.getById(mentorId);
      if (!dqs.exists()) {
        return;
      }
      this.mentor = new Mentor(dqs);
    },

    async updateProfile() {
      if (!this.isProfileDataValid()) {
        this.showUpdateFailedMessage();
        return;
      }
      this.isUpdating = true;
      this.mentor.updatedAt = serverTimestamp;
      try {
        if (this.file) {
          // storageに顔写真をアップロードし、URLを取得
          const url = await StorageHandler.upload(
            `mentors/${auth.currentUser!.uid}/profile`,
            this.file
          );

          // 顔写真のURLを保存するオブジェクトにもたせる
          this.mentor.profileImageURL = url;
        }

        // DBに書き込み
        await this.mentor.update();

        this.$router.push({ name: "mentor-profile-second" });
      } catch (error) {
        console.error("Error writing document: ", error);
        this.showUpdateFailedMessage();
      }
    },
    isProfileDataValid() {
      // ファイルがあれば必ずTrue、なければすでにprofileImageURLがあればTrueとする。
      return (
        (!!this.file || !!this.mentor.profileImageURL) &&
        this.mentor.name &&
        this.mentor.birthday &&
        this.mentor.university &&
        this.mentor.sex &&
        this.mentor.prefecture &&
        this.mentor.email &&
        this.mentor.profile
      );
    },
    showUpdateFailedMessage() {
      this.isUpdateFailed = true;
      setTimeout(() => {
        this.isUpdateFailed = false;
      }, 3000);
    },

    onUploadProfileImage($event: any) {
      console.log($event);
      this.file = $event.imageData;
    },
  },
});
