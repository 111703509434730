













































import Vue from "vue";
import { auth } from "../utils/firebase";
import MentorSignOut from "@/components/MentorSignOut.vue";
import SaveButtonBlue from "@/components/SaveButtonBlue.vue";
import MentorRepository from "@/repositories/mentor-repository";
import Mentor from "@/models/mentor";
import ConsultationsType from "@/components/form/fields/ConsultationInput.vue";
import HRExperienceInput from "@/components/form/fields/HRExperienceInput.vue";
import TermsOfServiceAcceptedInput from "@/components/form/fields/TermsOfServiceAcceptedInput.vue";

export default Vue.extend({
  components: {
    MentorSignOut,
    SaveButtonBlue,
    ConsultationsType,
    TermsOfServiceAcceptedInput,
    HRExperienceInput,
  },
  data(): {
    mentor: Mentor;
    isUpdating: any;
    isUpdateFailed: any;
    isLoadingCompleted: boolean;
    errorMessages: string[];
    isTermsOfServiceAcceptedBefore: boolean;
  } {
    return {
      mentor: new Mentor(),
      isUpdating: false,
      isUpdateFailed: false,
      isLoadingCompleted: false,
      errorMessages: [],
      isTermsOfServiceAcceptedBefore: false,
    };
  },
  async mounted() {
    await this.loadProfile();

    if (this.mentor.isReviewed) {
      // 登録済みの場合はhomeに飛ばす
      this.$router.push({ name: "mentor-home" });
      return;
    }

    this.isTermsOfServiceAcceptedBefore =
      !!this.mentor.isTermsOfServiceAccepted;
    this.isLoadingCompleted = true;
  },
  methods: {
    /**
     * プロフィール読み込み
     */
    async loadProfile() {
      const mentorId = auth.currentUser!.uid;

      // Userとユーザー情報を取得
      const dqs = await MentorRepository.getById(mentorId);
      if (!dqs.exists()) {
        return;
      }
      this.mentor = new Mentor(dqs);
    },
    /** プロフィール保存 */
    async updateProfile() {
      this.isUpdating = true;
      this.errorMessages = [];

      if (!this.isProfileDataValid()) {
        this.showUpdateFailedMessage();
        this.isUpdating = false;
        return;
      }

      try {
        await this.mentor.update();

        this.$router.push({
          name: "mentor-profile-finish",
          params: {
            mentorName: this.mentor.name,
          },
        });
      } catch (error) {
        console.error("Error writing document: ", error);
        this.showUpdateFailedMessage();
      }

      this.isUpdating = false;
    },
    // プロフィール保存失敗時のメッセージ表示
    showUpdateFailedMessage() {
      this.isUpdateFailed = true;
      setTimeout(() => {
        this.isUpdateFailed = false;
      }, 3000);
    },
    // プロフィールのバリデーション
    isProfileDataValid() {
      let isValid = true;
      if (this.mentor.consultations.length === 0) {
        isValid = false;
        this.errorMessages.push("こんな相談にのれます！を入力してください。");
      }

      if (!this.mentor.isTermsOfServiceAccepted) {
        isValid = false;
        this.errorMessages.push("利用規約に同意してください。");
      }

      if (!this.mentor.HRExperience) {
        isValid = false;
        this.errorMessages.push("採用経験を入力してください。");
      }

      return isValid;
    },
  },
});
