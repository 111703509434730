


















import Vue from "vue";
export default Vue.extend({
  name: "MentorMessageHeaderNavigation",
  props: ["user"],
  methods: {
    /**
     * スレッド一覧へ戻る
     */
    goToThreads() {
      this.$router.push({ name: "mentor-thread" });
    },
    /**
     * メッセージ中のユーザーの詳細ページへ遷移
     */
    goToUserDetail() {
      this.$router.push({
        name: "mentor-user-detail",
        params: {
          userId: this.user.id,
        },
      });
    },
  },
});
