




























































































































































































































































































import Mentor from "@/models/mentor";
import MentorRepository from "@/repositories/mentor-repository";
import Vue from "vue";

export default Vue.extend({
  data(): { mentors: Mentor[]; [key: string]: any } {
    return {
      fvImageSrc: require("@/assets/image/fv-bg.png"),
      mentors: [],
    };
  },
  async mounted() {
    await this.loadMentors();
  },
  methods: {
    async loadMentors() {
      const qs = await MentorRepository.readMentorByPageForLP();
      this.mentors = qs.docs.map((doc) => new Mentor(doc));
    },
  },
});
