
































































import Vue from "vue";
import MentorHeaderNavigation from "@/components/MentorHeaderNavigation.vue";
import MentorRepository from "@/repositories/mentor-repository";
import Mentor from "@/models/mentor";

export default Vue.extend({
  components: {
    MentorHeaderNavigation,
  },
  data(): { mentor: Mentor; isLoading: boolean } {
    return {
      isLoading: true,
      mentor: new Mentor(),
    };
  },
  async mounted() {
    await this.loadMentor();
  },
  methods: {
    async loadMentor() {
      const mentorId = this.$route.params.viewedMentorId;
      const documentQuerySnapshot = await MentorRepository.getById(mentorId);
      if (!documentQuerySnapshot.exists()) {
        // 存在しない場合はエラー
        // TODO HOMEにとばす
        throw new Error();
      }
      this.mentor = new Mentor(documentQuerySnapshot);
    },
  },
});
