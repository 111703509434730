
















































import Vue from "vue";
import MentorRepository from "@/repositories/mentor-repository";
import industries from "@/constants/industries";
import CheckboxInput from "@/components/form/fields/CheckboxInput.vue";
import Mentor from "@/models/mentor";
import consultationTypes from "@/constants/consultation-types";
import HRExperiences from "@/constants/hr-experiences";

export default Vue.extend({
  name: "MentorSearchArea",
  components: {
    CheckboxInput,
  },
  data(): {
    searchCondition: { industries: string[]; consultations: string[] };
    industries: typeof industries;
    isSearching: boolean;
    searchedMentors: Mentor[];
    consultationTypes: typeof consultationTypes;
    HRExperiences: typeof HRExperiences;
    HRExperiencesConditions: { [key: string]: boolean };
  } {
    return {
      searchCondition: {
        industries: [],
        consultations: [],
      },
      searchedMentors: [],
      isSearching: false,
      industries,
      consultationTypes,
      HRExperiences,
      HRExperiencesConditions: HRExperiences.reduce((output, experience) => {
        output[experience] = true;
        return output;
      }, {} as { [key: string]: boolean }),
    };
  },
  methods: {
    async searchMentor() {
      this.isSearching = true;

      if (
        Object.values(this.HRExperiencesConditions).filter((v) => v).length ===
        0
      ) {
        // 採用経験が全て無効化されている場合は
        this.emitMentors([]);
        this.isSearching = false;
        return;
      }

      if (
        this.searchCondition.industries.length === 0 &&
        this.searchCondition.consultations.length === 0
      ) {
        if (
          Object.values(this.HRExperiencesConditions).filter((v) => v)
            .length === 3
        ) {
          // 検索条件がないのでデフォルトの検索を行う
          const qs = await MentorRepository.readMentorByPage(10 ** 9);
          const searchedMentors = qs.docs.map((doc) => new Mentor(doc));
          this.emitMentors(searchedMentors);
        } else {
          // 採用経験でのみの絞り込み
          const qs = await MentorRepository.searchByHRExperiences(
            this.HRExperiencesConditions
          );
          const searchedMentors = qs.docs.map((doc) => new Mentor(doc));
          this.emitMentors(searchedMentors);
        }
        this.isSearching = false;
        return;
      }

      if (this.searchCondition.consultations.length === 0) {
        // industriesのみでの検索
        const qds = await MentorRepository.searchByIndustries(
          this.searchCondition.industries,
          this.HRExperiencesConditions
        );
        const searchedMentors = qds.map((doc) => new Mentor(doc));
        this.emitMentors(searchedMentors);
        this.isSearching = false;
        return;
      }

      if (this.searchCondition.industries.length === 0) {
        // consultationsのみでの検索
        const qds = await MentorRepository.searchByConsultations(
          this.searchCondition.consultations,
          this.HRExperiencesConditions
        );
        const searchedMentors = qds.map((doc) => new Mentor(doc));
        this.emitMentors(searchedMentors);
        this.isSearching = false;
        return;
      }

      // 両方で検索
      const qds = await MentorRepository.searchByIndustriesAndConsultations(
        this.searchCondition.industries,
        this.searchCondition.consultations,
        this.HRExperiencesConditions
      );
      const searchedMentors = qds.map((doc) => new Mentor(doc));
      this.emitMentors(searchedMentors);
      this.isSearching = false;
    },
    /**
     *
     */
    emitMentors(mentors: Mentor[]) {
      this.isSearching = false;
      this.$emit("on-finish-search", { mentors });
    },
  },
});
