















import Vue from "vue";
import SelectInput from "@/components/form/fields/selectInput.vue";
import consultationTypes from "@/constants/consultation-types";

/**
 * 相談したいことの入力フィールド
 */
export default Vue.extend({
  name: "ConsultationsInput",
  components: {
    SelectInput,
  },
  props: {
    originalValues: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "相談したいこと",
    },
  },
  data(): {
    consultationTypes: typeof consultationTypes;
    values: string[];
  } {
    return {
      consultationTypes,
      values: [],
    };
  },
  mounted() {
    this.values = this.originalValues as string[];
  },
});
