






import Vue from "vue";
import { db } from "./utils/firebase";
import {
  onSnapshot,
  query,
  collection,
  orderBy,
  limit,
} from "firebase/firestore";

export default Vue.extend({
  name: "App",

  data: () => ({
    isObservingUpdate: true,
  }),

  mounted() {
    this.handleUpdate();
  },
  methods: {
    /** アップデートが登録されたら画面を再読み込みする。 */
    handleUpdate() {
      const q = query(
        collection(db, "updates"),
        orderBy("createdAt", "desc"),
        limit(1)
      );
      onSnapshot(q, (_) => {
        if (this.isObservingUpdate) {
          // onsnpashotの初回に1つ取得するためそれはフラグだけ変えて無視する。
          this.isObservingUpdate = false;
        } else {
          // アップデートが配信されているのでページをリロード
          location.reload();
        }
      });
    },
  },
});
