
















































import Vue from "vue";
import { auth } from "../utils/firebase";
import MentorSignOut from "@/components/MentorSignOut.vue";
import SaveButtonBlue from "@/components/SaveButtonBlue.vue";
import MentorRepository from "@/repositories/mentor-repository";
import Mentor from "@/models/mentor";
import ExperienceInput from "@/components/form/fields/ExperienceInput.vue";

export default Vue.extend({
  components: {
    MentorSignOut,
    SaveButtonBlue,
    ExperienceInput,
  },
  data(): {
    mentor: Mentor;
    isUpdating: any;
    isUpdateFailed: any;
    isLoadingCompleted: boolean;
    errorMessages: string[];
  } {
    return {
      mentor: new Mentor(),
      isUpdating: false,
      // imageのプレビュー
      isUpdateFailed: false,
      isLoadingCompleted: false,
      errorMessages: [],
    };
  },
  async mounted() {
    await this.loadProfile();
    if (this.mentor.isReviewed) {
      // 登録済みの場合はhomeに飛ばす
      this.$router.push({ name: "mentor-home" });
      return;
    }
    this.isLoadingCompleted = true;
  },
  methods: {
    /**
     * プロフィール読み込み
     */
    async loadProfile() {
      const mentorId = auth.currentUser!.uid;

      // Userとユーザー情報を取得
      const dqs = await MentorRepository.getById(mentorId);
      if (!dqs.exists()) {
        return;
      }
      this.mentor = new Mentor(dqs);
      if (this.mentor.experiences.length === 0) {
        this.addExperienceForm();
      }
    },
    /** プロフィール保存 */
    async updateProfile() {
      this.isUpdating = true;
      this.errorMessages = [];

      if (!this.isProfileDataValid()) {
        this.showUpdateFailedMessage();
        this.isUpdating = false;
        return;
      }

      try {
        await this.mentor.update();

        this.$router.push({ name: "mentor-profile-third" });
      } catch (error) {
        console.error("Error writing document: ", error);
        this.showUpdateFailedMessage();
      }

      this.isUpdating = false;
    },
    // プロフィール保存失敗時のメッセージ表示
    showUpdateFailedMessage() {
      this.isUpdateFailed = true;
      setTimeout(() => {
        this.isUpdateFailed = false;
      }, 3000);
    },
    // プロフィールのバリデーション
    isProfileDataValid() {
      let isValid = true;
      if (this.mentor.experiences.length === 0) {
        this.errorMessages.push("経験は1つ以上入力してください");
        isValid = false;
      }

      this.mentor.experiences.forEach((experience, idx) => {
        if (
          !(
            experience.companyName &&
            experience.industry &&
            experience.jobTypes.length >= 1 &&
            experience.startYear &&
            experience.startMonth &&
            ((experience.endYear && experience.endMonth) ||
              experience.isPresent)
          )
        ) {
          this.errorMessages.push(
            `${idx + 1}番目の経験の内容を修正してください。`
          );
          isValid = false;
        }
      });

      return isValid;
    },
    addExperienceForm() {
      this.mentor.experiences.push({
        idx: new Date().getTime(),
        companyName: "",
        industry: "",
        jobTypes: [],
        startYear: 2021,
        startMonth: 1,
        endYear: 2021,
        endMonth: 1,
        isPresent: false,
      });
    },
    /**
     * 指定されたインデックスの経験を削除
     */
    removeExperienceForm(idx: number) {
      this.mentor.experiences = this.mentor.experiences.filter(
        (_: any, _idx: number) => _idx !== idx
      );
    },
  },
});
