const consultationTypes = [
  "自社採用",
  "自社インターン採用",
  "オンライン面談可",
  "自己分析",
  "業界分析",
  "企業研究",
  "SPI・筆記テスト",
  "面接対策",
  "ES添削",
  "地方就活",
  "理系就活",
  "院生就活",
  "留学生就活",
  "既卒就活",
  "外資就活",
  "女性就活（結婚・出産）",
  "LGBT就活",
  "ワークライフバランス",
  "就活初心者向け",
  "内定後の活動",
  "ベンチャーと大手の違い",
  "人生相談",
  "起業・事業相談",
  "2人で訪問OK",
] as const;

export default consultationTypes;
