














































import selectOptionsType from "@/types/select-options";
import Vue from "vue";

/**
 * 選択ボックス
 */
export default Vue.extend({
  name: "YearSelect",
  components: {},
  props: {
    originalValue: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  data(): {
    yearValue: number;
    monthValue: number;
    dateValue: number;
    yearOptions: selectOptionsType;
    monthOptions: selectOptionsType;
    dateOptions: selectOptionsType;
  } {
    return {
      yearValue: 1990,
      monthValue: 1,
      dateValue: 1,
      yearOptions: [...Array(14)].map((_, idx) => ({
        text: `${1990 + idx}`,
        value: 1990 + idx,
      })),
      monthOptions: [...Array(12)].map((_, idx) => ({
        text: `${1 + idx}`,
        value: 1 + idx,
      })),
      dateOptions: [...Array(31)].map((_, idx) => ({
        text: `${1 + idx}`,
        value: 1 + idx,
      })),
    };
  },
  mounted() {
    if (this.originalValue) {
      const originalValues = this.originalValue.split("-");
      this.yearValue = parseInt(originalValues[0]);
      this.monthValue = parseInt(originalValues[1]);
      this.dateValue = parseInt(originalValues[2]);
    } else {
      this.onChange();
    }
  },
  methods: {
    onChange() {
      this.$emit("on-input", {
        value:
          // 全部の値がある場合のみ連結してemit
          this.yearValue && this.monthValue && this.dateValue
            ? `${this.yearValue}-${("0" + this.monthValue).slice(-2)}-${(
                "0" + this.dateValue
              ).slice(-2)}`
            : undefined,
      });
    },
  },
});
