










































































import Vue from "vue";
import { auth } from "../utils/firebase";
import { signOut } from "firebase/auth";
import Mentor from "@/models/mentor";
import MentorRepository from "@/repositories/mentor-repository";

export default Vue.extend({
  name: "MentorNavigationContent",
  data(): {
    mentor: Mentor;
    bgImageSrc: string;
    errorMessage: string;
  } {
    return {
      mentor: new Mentor(),
      bgImageSrc: require("@/assets/image/nav_bg.png"),
      errorMessage: "",
    };
  },
  async mounted() {
    this.mentor = new Mentor(
      await MentorRepository.getById(auth.currentUser!.uid)
    );
  },
  methods: {
    async signOut() {
      try {
        // signoutしたらonAuthStateChangeで遷移
        await signOut(auth);
      } catch (error: any) {
        console.log(error);
        this.errorMessage = "ログインに失敗しました。";
      }
    },
  },
});
