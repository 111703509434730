import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { storage } from "./firebase";

/**
 * ストレージ処理を行うクラス
 */
export default class StorageHandler {
  static async download(path: string) {
    const storageRef = ref(storage, path);
    const url = await getDownloadURL(storageRef);
    return url;
  }

  /**
   * ファイルをStorageにアップロード
   * @param path
   * @param fileData
   * @returns アップロード先のURL
   */
  static async upload(path: string, fileData: File): Promise<string> {
    const storageRef = ref(storage, path);
    const result = await uploadBytes(storageRef, fileData, {
      contentType: "image/png",
    });
    return await getDownloadURL(result.ref);
  }
}
