var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"MentorProfile"},[_c('div',{staticClass:"app-container"},[_c('MentorHeaderNavigation'),(_vm.mentor.id)?_c('div',{staticClass:"app-contents-layout"},[_c('p',{staticClass:"card-title"},[_vm._v("登録情報")]),_c('div',{staticClass:"mentor-card-item"},[_c('div',{staticClass:"mentor-card-edit-wrapper"},[_c('router-link',{attrs:{"to":{ name: 'mentor-profile-edit' }}},[_c('img',{staticClass:"edit-icon",attrs:{"src":require("@/assets/image/icon/edit.svg"),"alt":""}})])],1),_c('div',{staticClass:"mentor-card-item__name-contents align-center"},[_c('img',{staticClass:"name-contents__img",attrs:{"src":_vm.mentor.profileImageURL,"alt":"Not Found"}}),_c('div',[_vm._v(_vm._s(_vm.mentor.name))])]),_c('p',{staticClass:"text-left mt-3"},[_vm._v("経歴")]),_vm._l((_vm.mentor.experiences),function(experience,eidx){return _c('div',{key:eidx,staticClass:"name-contents__text"},[_c('p',{staticClass:"name-contents__name"},[_vm._v(_vm._s(experience.companyName))]),_c('p',{staticClass:"name__sub"},[_vm._v("業界："+_vm._s(experience.industry))]),_c('p',{staticClass:"name__sub"},[_vm._v("職種："+_vm._s(experience.jobTypes.join(",")))]),_c('p',{staticClass:"name__sub"},[_vm._v(" 時期："),_c('span',[_vm._v(_vm._s(((experience.startYear) + "/" + (experience.startMonth))))]),_vm._v(" ~"),_c('span',[_vm._v(_vm._s(experience.isPresent ? "現在まで" : ((experience.endYear) + "/" + (experience.endMonth))))])])])}),_vm._l(([
            { title: 'プロフィール', value: _vm.mentor.profile },
            { title: '卒業大学', value: _vm.mentor.university },
            { title: '所在地', value: _vm.mentor.prefecture },
            {
              title: 'こんな相談にのれます！',
              value: _vm.mentor.consultations.join(','),
            },
            {
              title: '検索軸に活用するフラグ項目',
              value: _vm.mentor.HRExperience,
            } ]),function(item,idx){return _c('div',{key:'item' + idx,staticClass:"description_contents"},[_c('p',{staticClass:"description_contents__title"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"description_contents__description",style:({ whiteSpace: 'pre-wrap' })},[_vm._v(" "+_vm._s(item.value)+" ")])])})],2)]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }