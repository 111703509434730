<template>
    <header>
      <div class="header-container">
        <button
          @click="goToThreads">
          <img width="24" height="24" alt="スレッド一覧に戻る" class="" src="@/assets/image/icon/arrow.svg"/>
        </button>
        <div
          class="thread-header-container"
          @click="goToMentorDetail">
          <img
            class="thread-item__img"
            :src="mentorProfileImageUrl"
            width="40" height="40"
            alt="">
          <span
            v-if="mentor"
            class="thread-item__name">{{ mentor.name }}</span>
        </div>
        <div class="header-icon-menu-wrapper__user">
          <a href="/user/thread" class="nav-icon-wrapper">
            <img width="20" height="20" alt="message" class="nav-item__message" src="@/assets/image/icon/message.svg"/>
          </a>
          <a href="/user/search/mentor" class="nav-icon-wrapper">
            <img width="20" height="20" alt="search" src="@/assets/image/icon/search.svg"/>
          </a>
        </div>
      </div>
    </header>
</template>

<script>
export default {
  name: 'UserMessageHeaderNavigation',
  props: [
    'mentor',
    'mentorProfileImageUrl'
  ],
  methods: {
    goToThreads () {
      this.$router.push({name: 'user-thread'})
    },
    goToMentorDetail () {
      this.$router.push({
        name: 'mentor-search-detail',
        params: {
          mentorId: this.mentor.id
        }
      })
    }
  }
}

</script>

<style scoped>
  header{
    position: fixed;
    right: 0;
    left: 0;
    margin: auto;
    top: 0;
    width: 100%;
    height: 60px;
    box-shadow: 0 2px 4px rgba(0,0,0,.5);
    background: #fff;
    z-index: 10;
  }
  .header-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
    padding: 16px;
    box-sizing: border-box;
  }
  .nav-checkbox{
    display: none;
  }
  #nav-drawer{
    position: relative;
  }
  #nav-open{
    display: inline-block;
    width: 30px;
    height: 22px;
    vertical-align: middle;
  }
  #nav-open span, #nav-open span:before, #nav-open span:after {
    position: absolute;
    height: 3px;
    width: 24px;
    border-radius: 3px;
    background: #555;
    display: block;
    content: '';
    cursor: pointer;
  }
  #nav-open span:before {
    bottom: -8px;
  }
  #nav-open span:after {
    bottom: -16px;
  }
  #nav-close {
    display: none;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0;
    transition: .3s ease-in-out;
  }
  #nav-content {
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    max-width: 320px;
    height: 100%;
    background: #fff;
    transition: .3s ease-in-out;
    -webkit-transform: translateX(-105%);
    transform: translateX(-105%);
  }
  #nav-input:checked ~ #nav-close {
    display: block;
    opacity: .5;
  }
  #nav-input:checked ~ #nav-content {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    box-shadow: 6px 0 25px rgba(0,0,0,.15);
  }
  .nav-icon-wrapper{
    display: block;
    text-align: right;
  }
  .header-icon-menu-wrapper__user{
    position: absolute;
    right: 20px;
    top: 20px;
    display: flex;
  }
  .nav-item__message{
    padding-right: 16px;
  }
  button{
    border: none;
    background: white;
  }
</style>
