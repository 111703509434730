































import Vue from "vue";
import { auth } from "../utils/firebase";
import LoadingAnimation from "@/components/LoadingAnimation.vue";
import MentorRepository from "@/repositories/mentor-repository";

export default Vue.extend({
  components: {
    LoadingAnimation,
  },
  data() {
    return {
      mentorName: "",
      isLoading: true,
    };
  },
  async mounted() {
    const mentorDS = await MentorRepository.getById(auth.currentUser!.uid);
    if (mentorDS.exists()) {
      this.mentorName = mentorDS.data().name;

      if (mentorDS.data().isReviewed) {
        // 登録済みの場合はhomeに飛ばす
        this.$router.push({ name: "mentor-home" });
        return;
      }
    }
    this.isLoading = false;
  },
});
