








import Vue from "vue";
import CheckboxInput from "@/components/form/fields/CheckboxInput.vue";

/**
 * 利用規約に同意する用のチェックボックス
 */
export default Vue.extend({
  name: "TermsOfServiceAcceptedInput",
  components: {
    CheckboxInput,
  },
});
