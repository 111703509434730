import { FirebaseOptions, initializeApp } from "firebase/app";
import {
  getFirestore,
  connectFirestoreEmulator,
  serverTimestamp as _serverTimestamp,
  initializeFirestore,
} from "firebase/firestore";
import {
  getAuth,
  browserLocalPersistence,
  connectAuthEmulator,
} from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig: FirebaseOptions =
  process.env.NODE_ENV === "production"
    ? {
        apiKey: process.env.VUE_APP_APIKEY,
        authDomain: process.env.VUE_APP_AUTHDOMAIN,
        databaseURL: process.env.VUE_APP_DATABASEURL,
        projectId: process.env.VUE_APP_PROJECTID,
        storageBucket: process.env.VUE_APP_STORAGEBUCKET,
        messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
        appId: process.env.VUE_APP_APPID,
        measurementId: process.env.VUE_APP_MEASUREMENTID,
      }
    : {
        authDomain: process.env.VUE_APP_AUTHDOMAIN,
        apiKey: process.env.VUE_APP_APIKEY,
        databaseURL: process.env.VUE_APP_DATABASEURL,
        projectId: process.env.VUE_APP_PROJECTID,
        appId: process.env.VUE_APP_APPID,
        storageBucket: process.env.VUE_APP_STORAGEBUCKET,
      };

const firebaseApp = initializeApp(firebaseConfig);

export const db = initializeFirestore(firebaseApp, {
  ignoreUndefinedProperties: true,
});

export const auth = getAuth(firebaseApp);
export const AUTH_PERSISTENCE = browserLocalPersistence;
auth.setPersistence(AUTH_PERSISTENCE);

export const storage = getStorage(firebaseApp);

export const serverTimestamp = _serverTimestamp;

getAnalytics(firebaseApp);

// Emulatorの有効化
if (process.env.NODE_ENV != "production") {
  // Authentication用の設定
  connectAuthEmulator(auth, "http://localhost:9099/", {
    disableWarnings: true,
  });

  // Cloud Functions用の設定
  const functions = getFunctions(firebaseApp, "asia-northeast1");
  connectFunctionsEmulator(functions, "localhost", 5001);

  // Firestore用の設定
  connectFirestoreEmulator(db, "localhost", 9096);

  // storage用の設定
  connectStorageEmulator(storage, "localhost", 9094);
}
