


































import Vue from "vue";
import { auth } from "../utils/firebase";
import UserSignOut from "@/components/UserSignOut.vue";
import SaveButtonBlue from "@/components/SaveButtonBlue.vue";
import UserRepository from "@/repositories/user-repository";
import User from "@/models/user";
import ConsultationInput from "@/components/form/fields/ConsultationInput.vue";
import TermsOfServiceAcceptedInput from "@/components/form/fields/TermsOfServiceAcceptedInput.vue";

/**
 * ユーザープロフィールステップ2
 */
export default Vue.extend({
  name: "UserProfileThird",
  components: {
    UserSignOut,
    SaveButtonBlue,
    ConsultationInput,
    TermsOfServiceAcceptedInput,
  },
  data(): {
    user: User;
    isUpdating: boolean;
    isUpdateFailed: boolean;
    isLoadingCompleted: boolean;
    isTermsOfServiceAcceptedBefore: boolean;
  } {
    return {
      user: new User(),
      isUpdating: false,
      isUpdateFailed: false,
      isLoadingCompleted: false,
      isTermsOfServiceAcceptedBefore: false,
    };
  },
  async mounted() {
    // 途中離脱の場合のためにすでに保存済みのプロフィールをロードする。
    await this.loadProfile();
    this.isLoadingCompleted = true;
    this.isTermsOfServiceAcceptedBefore = !!this.user.isTermsOfServiceAccepted;
  },
  methods: {
    /**
     * 途中離脱の場合すでに保存済みのプロフィールをロードする。
     */
    async loadProfile() {
      const userId = auth.currentUser!.uid;

      // Userとユーザー情報を取得
      const dqs = await UserRepository.getById(userId);
      if (!dqs.exists()) {
        return;
      }
      this.user = new User(dqs);
    },
    /**
     * プロフィールの更新処理
     */
    async updateProfile() {
      // validation
      if (!this.isProfileDataValid()) {
        this.showUpdateFailedMessage();
        return;
      }
      // 保存中状態にする
      this.isUpdating = true;

      try {
        // プロフィール入力済みにする
        this.user.isProfileCompleted = true;
        // firestoreに保存
        await this.user.update();
      } catch (error: any) {
        console.error(error);
        this.showUpdateFailedMessage();
        return;
      }

      this.$router.push({ name: "user-home" });
    },
    /**
     * 入力されたプロフィール項目が正しいか判定。
     * 名前とプロフィールが存在するかの確認をする。
     */
    isProfileDataValid(): boolean {
      console.log(this.user);
      return (
        this.user.consultations.length >= 1 &&
        this.user.isTermsOfServiceAccepted
      );
    },
    /**
     * 保存が失敗した際のメッセージを表示する。
     */
    showUpdateFailedMessage(): void {
      this.isUpdateFailed = true;
      setTimeout(() => {
        this.isUpdateFailed = false;
      }, 3000);
    },
  },
});
