










import Vue from "vue";
import TextInput from "@/components/form/fields/textInput.vue";

/**
 * 会社名入力フィールド
 */
export default Vue.extend({
  name: "CompanyInput",
  components: {
    TextInput,
  },
  props: {
    originalValue: {
      type: String,
      default: "",
    },
  },
});
