<template>
  <div>
    <button
      @click="signOut"
      class="menu-link">
      サインアウト
    </button>
  </div>
</template>

<script>
import { auth } from '../utils/firebase'

export default {
  methods: {
    async signOut () {
      try {
        // onAuthStateChangedで自動的にsignin画面に遷移
        await auth.signOut()
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>
.menu-link{
  display: inline-block;
  margin: 16px 0;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  border: none;
  background: #fff;
}
.menu-link:active{
  opacity: .8;
}
.menu-link:hover{
  opacity: .8;
}
</style>
