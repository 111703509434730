





























import Vue from "vue";

export default Vue.extend({
  name: "ImageInput",
  props: {
    imageURL: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "顔写真",
    },
  },
  data(): {
    imagePreviewURL: string;
    wrongFileTypeMessage: string;
    file: any;
  } {
    return {
      /** プロフィール画像をアップロード時、ローカルにしか画像データがないためそのURLを保持 */
      imagePreviewURL: "",
      // 拡張子エラーメッセ
      wrongFileTypeMessage: "",
      file: "",
    };
  },
  mounted() {
    this.imagePreviewURL = this.imageURL;
  },
  methods: {
    /**
     * inputでファイルがセットされた時の処理。
     */
    setImage(e: any) {
      this.file = e.target.files[0];
      // 行頭がimageで行末がpng or jpegなら正しい
      if (this.file && this.file.type.match(/^image\/(png|jpeg|gif)$/)) {
        this.wrongFileTypeMessage = "";
        this.imagePreviewURL = URL.createObjectURL(this.file);
        this.$emit("on-upload-image", { imageData: this.file });
      } else {
        // input acceptで制限はかけているが念の為エラー処理
        this.file = "";
        this.wrongFileTypeMessage =
          "ファイルの拡張子は.jpg、.pngまたは.gifのみです";
        this.imagePreviewURL = "";
      }
    },
  },
});
