const companyTypes = [
  "大手企業",
  "中小老舗",
  "ベンチャー",
  "スタートアップ",
  "外資系",
  "こだわらない",
] as const;

export default companyTypes;
