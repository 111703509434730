



























import Vue from "vue";
import UserHeaderNavigation from "@/components/UserHeaderNavigation.vue";
import MentorProfileCard from "@/components/MentorProfileCard.vue";
import LoadingAnimation from "@/components/LoadingAnimation.vue";
import InfiniteLoading from "@/components/InfiniteLoading.vue";
import StorageHandler from "@/utils/storage";
import MentorRepository from "@/repositories/mentor-repository";
import Mentor from "@/models/mentor";

export default Vue.extend({
  components: {
    InfiniteLoading,
    UserHeaderNavigation,
    LoadingAnimation,
    MentorProfileCard,
  },
  data(): {
    mentors: Mentor[];
    isLoading: boolean;
    searchPriority: number;
    dialog: boolean;
  } {
    return {
      isLoading: true,
      mentors: [],
      // 優先度値が高いものから表示されるためかなり大きな値を初期値にしておく。
      searchPriority: 10 ** 9,
      dialog: false,
    };
  },
  methods: {
    readProfileImages() {
      this.mentors.forEach((_mentor: any) => {
        if (!_mentor.profileImageUrl) {
          this.getProfileUrl(_mentor.id)
            .then((result: any) => {
              this.$set(_mentor, "profileImageUrl", result);
            })
            .catch((error: any) => {
              console.error(error);
            });
        }
      });
    },
    async getProfileUrl(mentorId: string) {
      try {
        return StorageHandler.download(`mentors/${mentorId}/images/profile`);
      } catch (error) {
        console.error(error);
        return "";
      }
    },
    /**
     * 無限スクロールの処理。
     * 画面ロード時にいきなり走るようになっている。
     */
    async infiniteHandler(e: any) {
      const $state = e.$state;
      try {
        // 順序を取得して並べ替え
        const qs = await MentorRepository.readMentorByPage(this.searchPriority);
        if (qs.empty) {
          // 新しいデータがとれなかった場合は無限スクロールを無効化する
          if (this.isLoading) {
            this.isLoading = false;
          }
          $state.complete();
          return;
        }

        // 取得したデータの中で一番優先度値が低いものを保持しておく。
        this.searchPriority = qs.docs.slice(-1)[0].data().searchPriority;
        const mentors = qs.docs.map((doc) => new Mentor(doc));
        // 既存と新規のメンターをあわせる。
        this.mentors = this.mentors.concat(mentors);
        // this.readProfileImages();
        this.isLoading = false;
        // infiniteHandlerを完了にする
        $state.loaded();
      } catch (err) {
        console.error(err);
      }
    },
  },
});
