












import Vue from "vue";

export default Vue.extend({
  name: "SaveButtonBlue",
  props: {
    isUpdateFailed: {
      type: Boolean,
    },
    isUpdating: {
      type: Boolean,
    },
    title: {
      type: String,
      default: "登録",
    },
  },
});
