













import Vue from "vue";

export default Vue.extend({
  name: "CheckboxInput",
  components: {},
  props: {
    originalValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    maxLength: {
      type: Number,
      default: 20,
    },
  },
  data(): {
    isChecked: boolean;
  } {
    return {
      isChecked: false,
    };
  },
  mounted() {
    this.isChecked = this.originalValue;
  },
  methods: {
    onChange(e: any) {
      this.$emit("on-change", { value: this.isChecked });
    },
  },
});
