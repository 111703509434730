















import Vue from "vue";
import TextInput from "@/components/form/fields/textInput.vue";

/**
 * Eメール入力フィールド
 */
export default Vue.extend({
  name: "EmailInput",
  components: {
    TextInput,
  },
  props: {
    originalValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      placeholder: `例）はじめまして、私は現在ITベンチャー企業で採用責任者として働いております。
元々はリクルートで新卒・中途の採用担当者として、5年間ほど働いていました。採用担当歴が長いため、「自己分析」「面接対策」「企業研究」等様々な面でご相談に乗れるかと思います！ お気軽にご相談ください！`,
      value: "",
    };
  },
  mounted() {
    if (this.originalValue) {
      this.value = this.originalValue;
    }
  },
});
