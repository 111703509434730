










import Vue from "vue";
import SelectInput from "@/components/form/fields/selectInput.vue";
import schoolYearOptions from "@/constants/school-year-options";
import selectOptionsType from "@/types/select-options";

const title = "大学/専門学校";

/**
 * 学年選択フィールド
 */
export default Vue.extend({
  name: "SchoolYearInput",
  components: {
    SelectInput,
  },
  props: {
    originalValue: {
      type: String,
      default: "",
    },
  },
  data(): {
    title: typeof title;
    schoolYearOptions: selectOptionsType;
  } {
    return {
      title,
      schoolYearOptions: schoolYearOptions.map((o) => ({ text: o, value: o })),
    };
  },
});
