






































import Vue from "vue";
import { auth } from "../utils/firebase";
import UserRepository from "@/repositories/user-repository";
import MessageThread from "@/models/message-thread";
import MentorHeaderNavigation from "@/components/MentorHeaderNavigation.vue";
import InfiniteLoading from "@/components/InfiniteLoading.vue";
import MentorRepository from "@/repositories/mentor-repository";
import User from "@/models/user";

export default Vue.extend({
  name: "MentorThread",
  components: {
    InfiniteLoading,
    MentorHeaderNavigation,
  },
  data(): {
    threads: MessageThread[];
    users: User[];
    isLoading: boolean;
    lastThreadUpdatedAt: null | Date;
  } {
    return {
      threads: [],
      users: [],
      isLoading: true,
      lastThreadUpdatedAt: null,
    };
  },
  async mounted() {
    // メンターを取得
    await this.loadThreads();
    await this.loadUsers();
    this.isLoading = false;
  },
  methods: {
    /**
     *
     */
    async loadThreads() {
      // メッセージスレッドの読み込み
      const userThreadsQS = await MentorRepository.getMessageThreads(
        auth.currentUser!.uid
      );

      this.threads = userThreadsQS.docs.map((dqs) => new MessageThread(dqs));
    },
    /**
     *
     */
    async loadUsers() {
      // スレッドに含まれるメンター情報を取得
      if (this.threads.length === 0) {
        // スレッドがない場合はスキップ
        return;
      }
      const userIds = this.threads.map((thread) => thread.userId);
      const qs = await UserRepository.getByIds(userIds);
      this.users = qs.docs.map((doc) => new User(doc));
    },

    async openThread(idx: number) {
      this.$router.push({
        name: "mentor-message",
        params: { threadId: this.threads[idx].userId },
      });
    },

    formatDate(date: any) {
      // 本日のものか判定
      const now = new Date();
      const currentYear = now.getFullYear();
      const currentMonth = now.getMonth() + 1;
      const currentDate = now.getDate();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const _date = date.getDate();
      const hour = date.getHours();
      const minute = date.getMinutes();
      if (
        currentYear <= year &&
        currentMonth <= month &&
        currentDate <= _date
      ) {
        // 本日のスレッドなので、時刻を返す
        return `${("0" + hour).slice(-2)}:${("0" + minute).slice(-2)}`;
      } else {
        // 本日以前のスレッドなので、日付を返す
        return `${("0" + month).slice(-2)}/${("0" + _date).slice(-2)}`;
      }
    },

    getUserById(id: string) {
      return this.users.find((user) => user.id === id) || new User();
    },
  },
});
