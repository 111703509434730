import messageType from "@/types/message";
import { DocumentSnapshot } from "@firebase/firestore";

/**
 * メッセージモデル
 */
export default class Message implements messageType {
  id: string | null = null;
  senderId;
  content;
  createdAt: Date;
  updatedAt: Date;

  constructor(documentSnapshot: DocumentSnapshot) {
    const data = documentSnapshot.data();
    if (!data) {
      throw new Error();
    }
    this.id = data.id;
    this.senderId = data.senderId;
    this.content = data.content;
    this.createdAt = data.createdAt ? data.createdAt.toDate() : new Date();
    this.updatedAt = data.updatedAt ? data.updatedAt.toDate() : new Date();
  }

  toJSON() {
    return {
      id: this.id,
      senderId: this.senderId,
      content: this.content,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }
}
