

























import Vue from "vue";
import MentorSearchArea from "@/components/MentorSearchArea.vue";
import UserHeaderNavigation from "@/components/UserHeaderNavigation.vue";
import MentorProfileCard from "@/components/MentorProfileCard.vue";
import Mentor from "@/models/mentor";
import MentorRepository from "@/repositories/mentor-repository";

export default Vue.extend({
  components: {
    MentorSearchArea,
    UserHeaderNavigation,
    MentorProfileCard,
  },
  data(): {
    isUpdating: boolean;
    isLoading: boolean;
    mentors: Mentor[];
  } {
    return {
      isUpdating: false,
      isLoading: true,
      mentors: [],
    };
  },
  async mounted() {
    // メンターを取得
    this.loadMentors();
  },
  methods: {
    async loadMentors() {
      const qs = await MentorRepository.readMentorByPage(10 ** 9);
      this.mentors = qs.docs.map((doc) => new Mentor(doc));
      this.isLoading = false;
    },
    /**
     * メンター検索を行う
     */
    onFinishSearch(e: any) {
      this.mentors = e.mentors;
    },
  },
});
