





































































import Vue from "vue";
import { auth } from "../utils/firebase";
import LoadingAnimation from "@/components/LoadingAnimation.vue";
import { createUserWithEmailAndPassword } from "firebase/auth";
import UserRepository from "@/repositories/user-repository";

export default Vue.extend({
  components: {
    LoadingAnimation,
  },
  data() {
    return {
      email: "",
      password: "",
      errorMessage: "",
      isSigningUp: false,
      isLoading: true,
    };
  },
  async mounted() {
    this.isLoading = false;
  },
  methods: {
    async signUp() {
      try {
        this.isSigningUp = true;
        const authResult = await createUserWithEmailAndPassword(
          auth,
          this.email,
          this.password
        );
        const user = authResult.user;
        if (!user.email) {
          throw new Error("no user email");
        }

        await UserRepository.createUser(user.email, authResult.user.uid);
        this.$router.push({ name: "user-profile-first" });
      } catch (error: any) {
        console.error(error);
        this.isSigningUp = false;
        if (error.code === "auth/invalid-email") {
          this.errorMessage = "正しいメールアドレスを入力してください";
        } else if (error.code === "auth/email-already-in-use") {
          this.errorMessage = "メールアドレスは既に登録されています";
        } else if (error.code === "auth/weak-password") {
          this.errorMessage = "パスワードは6文字以上にしてください";
        } else {
          this.errorMessage = "サインアップに失敗しました";
        }
      }
    },
  },
});
