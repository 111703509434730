










import Vue from "vue";
import SelectInput from "@/components/form/fields/selectInput.vue";
import selectOptionsType from "@/types/select-options";
import bunriClassifications from "@/constants/bunri-classifications";

const title = "大学/専門学校";

/**
 * 学年選択フィールド
 */
export default Vue.extend({
  name: "BunriClassificationInput",
  components: {
    SelectInput,
  },
  props: {
    originalValue: {
      type: String,
      default: "",
    },
  },
  data(): {
    title: typeof title;
    bunriClassificationOptions: selectOptionsType;
  } {
    return {
      title,
      bunriClassificationOptions: Object.values(bunriClassifications),
    };
  },
});
