import { db } from "@/utils/firebase";
import {
  onSnapshot,
  query,
  collection,
  orderBy,
  limit,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  setDoc,
  where,
  serverTimestamp,
  startAfter,
  addDoc,
} from "firebase/firestore";

/**
 * firestoreへのアクセスを行う
 */
export default class MentorRepository {
  static collectionName = "mentors";
  static messageThreads = "messageThreads";
  static messages = "messages";

  /**
   * IDでメンターを取得
   */
  static async getById(id: string) {
    const ref = doc(db, MentorRepository.collectionName, id);
    return await getDoc(ref);
  }

  static async getByIds(ids: string[]) {
    const q = query(
      collection(db, MentorRepository.collectionName),
      where("isReviewed", "==", true),
      where("id", "in", ids),
      limit(500)
    );
    return await getDocs(q);
  }

  static async updateLastActiveAt(id: string) {
    const ref = doc(db, MentorRepository.collectionName, id);
    return await updateDoc(ref, {
      lastActiveAt: new Date(),
    });
  }

  /**
   *
   */
  static async getMessageThreads(mentorId: string) {
    const q = query(
      collection(
        db,
        MentorRepository.collectionName,
        mentorId,
        MentorRepository.messageThreads
      ),
      orderBy("updatedAt", "desc"),
      limit(500)
    );
    return await getDocs(q);
  }

  static async update(mentorId: string, data: any) {
    const ref = doc(db, MentorRepository.collectionName, mentorId);
    await setDoc(ref, data);
  }

  static async updateFields(mentorId: string, data: any) {
    // 更新時間を更新
    data.updatedAt = serverTimestamp();
    const ref = doc(db, MentorRepository.collectionName, mentorId);
    await updateDoc(ref, data);
  }

  static async createMentor(email: string, userId: string) {
    await setDoc(doc(db, MentorRepository.collectionName, userId), {
      email,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  }

  /**
   *
   * @returns
   */
  static async readMentorByPageForLP() {
    const q = query(
      collection(db, MentorRepository.collectionName),
      where("isReviewed", "==", true),
      orderBy("searchPriority", "desc"),
      startAfter(10 ** 9),
      limit(5)
    );
    return await getDocs(q);
  }

  /**
   *
   * @param searchPriority
   * @returns
   */
  static async readMentorByPage(searchPriority: number) {
    const q = query(
      collection(db, MentorRepository.collectionName),
      where("isReviewed", "==", true),
      orderBy("searchPriority", "desc"),
      startAfter(searchPriority),
      limit(10)
    );
    return await getDocs(q);
  }

  /**
   * メンターのメッセージスレッドの中から与えられたユーザーIDと一致するスレッドを取得
   */
  static async getMessageThreadByUserId(userId: string, mentorId: string) {
    const docRef = doc(
      db,
      MentorRepository.collectionName,
      mentorId,
      MentorRepository.messageThreads,
      userId
    );
    return await getDoc(docRef);
  }

  /**
   *
   * @param userId
   * @param mentorId
   * @returns
   */
  static async getMessagesByUserId(userId: string, mentorId: string) {
    const messageThreadCollectionRef = collection(
      db,
      MentorRepository.collectionName,
      mentorId,
      MentorRepository.messageThreads,
      userId,
      MentorRepository.messages
    );
    const q = query(
      messageThreadCollectionRef,
      orderBy("createdAt", "asc"),
      limit(500)
    );
    return await getDocs(q);
  }

  /**
   * メッセージを送信。
   * メンター側のみに書き込み、ユーザー側の書き込みはfunctionsに任せる
   * @param mentorId
   * @param userId
   * @param content
   * @returns
   */
  static async sendMessage(mentorId: string, userId: string, content: string) {
    const messageThreadCollectionRef = collection(
      db,
      MentorRepository.collectionName,
      mentorId,
      MentorRepository.messageThreads,
      userId,
      MentorRepository.messages
    );
    const data = {
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      content,
      senderId: mentorId,
    };
    return await addDoc(messageThreadCollectionRef, data);
  }

  /**
   *
   * @param industries
   * @returns
   */
  static async searchByIndustries(
    industries: string[],
    HRExperiences: {
      [key: string]: boolean;
    }
  ) {
    const q = query(
      collection(db, MentorRepository.collectionName),
      where("isReviewed", "==", true),
      where("industries", "array-contains-any", industries),
      limit(500)
    );

    const qs = await getDocs(q);
    // 採用経験で絞り込み
    return qs.docs.filter((doc) =>
      Object.entries(HRExperiences)
        .filter((entry) => entry[1])
        .map((entry) => entry[0])
        .includes(doc.data().HRExperience)
    );
  }

  /**
   *
   * @param consultations
   */
  static async searchByConsultations(
    consultations: string[],
    HRExperiences: {
      [key: string]: boolean;
    }
  ) {
    const q = query(
      collection(db, MentorRepository.collectionName),
      where("isReviewed", "==", true),
      where("consultations", "array-contains-any", consultations),
      limit(500)
    );

    const qs = await getDocs(q);
    // 採用経験で絞り込み
    return qs.docs.filter((doc) =>
      Object.entries(HRExperiences)
        .filter((entry) => entry[1])
        .map((entry) => entry[0])
        .includes(doc.data().HRExperience)
    );
  }

  /**
   * industry, consultations両方一致するメンターを取得
   * @param industries
   * @param consultations
   */
  static async searchByIndustriesAndConsultations(
    industries: string[],
    consultations: string[],
    HRExperiences: {
      [key: string]: boolean;
    }
  ) {
    // array-contains-anyを2つ使えないので、industries, consultationsにそれぞれ一致するメンターを引っ張ってきてから両方一致するものを絞り込む
    // 最適化するには配列ではなくオブジェクト化したほうがよさそう。
    const industriesMentorsQDS = await MentorRepository.searchByIndustries(
      industries,
      HRExperiences
    );
    const consultationsQDS = await MentorRepository.searchByConsultations(
      consultations,
      HRExperiences
    );

    const consultationsMentorsIds = consultationsQDS.map((doc) => doc.id);
    return industriesMentorsQDS.filter((doc) =>
      consultationsMentorsIds.includes(doc.id)
    );
  }

  /**
   *
   * @param consultations
   */
  static async searchByHRExperiences(HRExperiences: {
    [key: string]: boolean;
  }) {
    const q = query(
      collection(db, MentorRepository.collectionName),
      where("isReviewed", "==", true),
      // 採用経験で絞り込み
      where(
        "HRExperience",
        "in",
        Object.entries(HRExperiences)
          .filter((entry) => entry[1])
          .map((entry) => entry[0])
      ),
      limit(500)
    );
    return await getDocs(q);
  }

  /**
   *
   */
  static async subscribeToThread({
    userId,
    mentorId,
    callback,
  }: {
    userId: string;
    mentorId: string;
    callback: any;
  }) {
    const q = query(
      collection(db, "mentors", mentorId, "messageThreads", userId, "messages"),
      orderBy("createdAt", "desc"),
      limit(1)
    );

    return onSnapshot(q, (qs) => {
      callback(qs);
    });
  }
}
