















import Vue from "vue";
import companyTypes from "@/constants/company-types";

/**
 * 企業属性複数選択フィールド
 */
export default Vue.extend({
  name: "CompanyTypesInput",
  props: {
    originalValues: {
      type: Array,
      default: () => [],
    },
  },
  data(): {
    companyTypes: typeof companyTypes;
    values: string[];
  } {
    return {
      values: [],
      companyTypes,
    };
  },
  mounted() {
    this.values = this.originalValues as string[];
  },
});
