const schoolYearOptions = [
  "大学1年生",
  "大学2年生",
  "大学3年生",
  "大学4年生",
  "修士課程1年目",
  "修士課程2年目",
  "博士課程1年目",
  "博士課程2年目",
  "博士課程3年目",
] as const;

export default schoolYearOptions;
