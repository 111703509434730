

























































import Vue from "vue";
import { auth } from "../utils/firebase";
import LoadingAnimation from "@/components/LoadingAnimation.vue";
import { signInWithEmailAndPassword } from "firebase/auth";
import UserRepository from "@/repositories/user-repository";

export default Vue.extend({
  name: "UserSignIn",
  components: {
    LoadingAnimation,
  },
  data() {
    return {
      email: "",
      password: "",
      errorMessage: "",
      /** サインイン処理中かどうかのフラグ。入力制御用。 */
      isSigningIn: false,
      isLoading: true,
    };
  },
  async mounted() {
    this.isLoading = false;
  },
  methods: {
    async signIn() {
      this.isSigningIn = true;

      try {
        const result = await signInWithEmailAndPassword(
          auth,
          this.email,
          this.password
        );
        const authUser = result.user;
        if (!authUser || !authUser.email) {
          // 失敗
          throw new Error();
        }

        const documentQuerySnapshot = await UserRepository.getById(
          authUser.uid
        );
        // 認証ユーザーのDBユーザーが存在しない場合は作成
        // どういうケースか確認
        if (!documentQuerySnapshot.exists()) {
          await UserRepository.createUser(authUser.email, authUser.uid);
          this.$router.push({ name: "user-profile-first" });
          return;
        }

        // プロフィール入力状態によってページ遷移
        const isProfileCompleted =
          documentQuerySnapshot.data().isProfileCompleted;
        // TODO ホームに全員飛ばしてから遷移させればいい可能性
        if (isProfileCompleted) {
          this.$router.push({ name: "user-home" });
        } else {
          this.$router.push({ name: "user-profile-first" });
        }
      } catch (error: any) {
        if (error.code === "auth/invalid-email") {
          this.errorMessage = "正しいメールアドレスを入力してください";
        } else if (error.code === "auth/wrong-password") {
          this.errorMessage = "パスワードが違います";
        } else {
          this.errorMessage = "ログインが失敗しました";
        }
      } finally {
        this.isSigningIn = false;
      }
    },
  },
});
