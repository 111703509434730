



































































import Vue from "vue";
import { formatDateByToday } from "@/utils/dateUtils";
import UserMessageHeaderNavigation from "./UserMessageHeaderNavigation.vue";
import MentorRepository from "@/repositories/mentor-repository";
import Message from "@/models/message";
import UserRepository from "@/repositories/user-repository";
import { auth } from "@/utils/firebase";
import MessageThread from "@/models/message-thread";
import Mentor from "@/models/mentor";

export default Vue.extend({
  name: "UserMessage",
  components: {
    UserMessageHeaderNavigation,
  },
  data(): {
    mentor: Mentor;
    messages: Message[];
    thread: MessageThread;
    isReady: boolean;
    [key: string]: any;
  } {
    return {
      messages: [],
      draft: "",
      mentor: new Mentor(),
      user: this.$route.params.authUser,
      thread: new MessageThread(),
      isMentorLoaded: false,
      lastMessage: null,
      messageListener: null,
      isReady: false,
    };
  },
  async mounted() {
    await this.loadThread();
    await this.loadMentor();
    await this.subscribeToThread();

    await this.loadMessages();

    // 一番下までスクロール
    this.scrollIntoLastMessage();
  },
  destroyed() {
    if (this.messageListener) {
      // リスナーをunmountしておく
      this.messageListener();
    }
  },
  methods: {
    /** 一番下までスクロール */
    scrollIntoLastMessage() {
      this.lastMessage = this.$refs.lastMessage;
      if (this.lastMessage) {
        this.lastMessage.scrollIntoView();
      }
    },
    /**
     *
     */
    async loadThread() {
      const dqs = await UserRepository.getMessageThreadByMentorId(
        auth.currentUser!.uid,
        this.$route.params.mentorId
      );
      this.thread = new MessageThread(dqs);
    },
    /**
     *
     */
    async loadMessages() {
      const messagesQS = await UserRepository.getMessagesByMentorId(
        auth.currentUser!.uid,
        this.$route.params.mentorId
      );
      this.messages = messagesQS.docs.map((doc) => new Message(doc));
      this.isReady = true;
    },
    /**
     *
     */
    async loadMentor() {
      const dqs = await MentorRepository.getById(this.thread.mentorId);
      if (!dqs.exists()) {
        throw Error("no mentor");
      }

      this.mentor = new Mentor(dqs);
      this.isMentorLoaded = true;
    },
    async subscribeToThread() {
      this.messageListener = await UserRepository.subscribeToThread({
        userId: auth.currentUser!.uid,
        mentorId: this.mentor.id!,
        callback: (value: any) => {
          if (!this.isReady) {
            // 初回は何もしない
            return;
          }

          this.messages = value.docs.reduce((output: Message[], doc: any) => {
            output.push(new Message(doc));
            return output;
          }, this.messages);

          this.scrollIntoLastMessage();
        },
      });
    },
    /**
     *
     */
    async sendMessage() {
      await UserRepository.sendMessage(
        auth.currentUser!.uid,
        this.mentor.id!,
        this.draft.trim()
      );

      this.draft = "";

      await this.loadMessages();

      this.scrollIntoLastMessage();
    },
    formatDate(date: any) {
      return formatDateByToday(date);
    },
  },
});
