import Vue from "vue";
import Router from "vue-router";
import getAuthUser from "../utils/get-auth-user";

import sendPasswordResetEmail from "@/components/sendPasswordResetEmail.vue";
import TermsOfServices from "@/components/TermsOfServices.vue";
import PrivacyPolicy from "@/components/PrivacyPolicy.vue";
import MentorRoot from "@/components/MentorRoot.vue";
import MentorSignUp from "@/components/MentorSignUp.vue";
import MentorSignIn from "@/components/MentorSignIn.vue";
import MentorProfile from "@/components/MentorProfile.vue";
import MentorProfileEdit from "@/components/MentorProfileEdit.vue";
import MentorProfileFirst from "@/components/MentorProfileFirst.vue";
import MentorProfileSecond from "@/components/MentorProfileSecond.vue";
import MentorProfileThird from "@/components/MentorProfileThird.vue";
import MentorFinish from "@/components/MentorFinish.vue";
import MentorThread from "@/components/MentorThread.vue";
import MentorMessage from "@/components/MentorMessage.vue";
import MentorHome from "@/components/MentorHome.vue";
import MentorInfoDetail from "@/components/MentorInfoDetail.vue";
import MentorUserDetail from "@/components/MentorUserDetail.vue";

import UserRoot from "@/components/UserRoot.vue";
import UserSignUp from "@/components/UserSignUp.vue";
import UserSignIn from "@/components/UserSignIn.vue";
import UserLandingPage from "@/components/UserLandingPage.vue";
import UserHome from "@/components/UserHome.vue";
import UserProfile from "@/components/UserProfile.vue";
import UserProfileEdit from "@/components/UserProfileEdit.vue";
import UserProfileFirst from "@/components/UserProfileFirst.vue";
import UserProfileSecond from "@/components/UserProfileSecond.vue";
import UserProfileThird from "@/components/UserProfileThird.vue";
import UserThread from "@/components/UserThread.vue";
import UserMessage from "@/components/UserMessage.vue";
import MentorSearch from "@/components/MentorSearch.vue";
import MentorSearchDetail from "@/components/MentorSearchDetail.vue";
import NotFoundComponent from "@/components/NotFoundComponent.vue";

import MentorRepository from "@/repositories/mentor-repository";
import Mentor from "@/models/mentor";
import UserRepository from "@/repositories/user-repository";
import User from "@/models/user";

Vue.use(Router);

const mentorSignInName = "mentor-signin";
const mentorSignUpName = "mentor-signup";
const userSignInName = "user-signin";
const userSignUpName = "user-signup";

var router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: { name: "user-top" },
    },
    {
      path: "/reset-password",
      name: "send-password-reset-email",
      component: sendPasswordResetEmail,
    },
    {
      path: "/terms",
      name: "terms",
      component: TermsOfServices,
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: PrivacyPolicy,
    },
    {
      path: "/mentor",
      component: MentorRoot,
      children: [
        {
          path: "/",
          redirect: "signin",
        },
        {
          path: "signup",
          name: mentorSignUpName,
          component: MentorSignUp,
        },
        {
          path: "signin",
          name: mentorSignInName,
          component: MentorSignIn,
        },
        {
          path: "profile",
          name: "mentor-profile",
          component: MentorProfile,
          meta: {
            isMentorAuthRequired: true,
            isReviewRequired: true,
          },
        },
        {
          path: "profile/edit",
          name: "mentor-profile-edit",
          component: MentorProfileEdit,
          meta: {
            isMentorAuthRequired: true,
            isReviewRequired: true,
          },
        },
        {
          path: "profile/first",
          name: "mentor-profile-first",
          component: MentorProfileFirst,
          meta: {
            isMentorAuthRequired: true,
          },
        },
        {
          path: "profile/second",
          name: "mentor-profile-second",
          component: MentorProfileSecond,
          meta: {
            isMentorAuthRequired: true,
          },
        },
        {
          path: "profile/third",
          name: "mentor-profile-third",
          component: MentorProfileThird,
          meta: {
            isMentorAuthRequired: true,
          },
        },
        {
          path: "profile/finish",
          name: "mentor-profile-finish",
          component: MentorFinish,
          meta: {
            isMentorAuthRequired: true,
          },
        },
        {
          path: "thread",
          name: "mentor-thread",
          component: MentorThread,
          meta: {
            isMentorAuthRequired: true,
            isReviewRequired: true,
          },
        },
        {
          path: "thread/:threadId",
          name: "mentor-message",
          component: MentorMessage,
          meta: {
            isMentorAuthRequired: true,
            isReviewRequired: true,
          },
        },
        {
          path: "home",
          name: "mentor-home",
          component: MentorHome,
          meta: {
            isMentorAuthRequired: true,
            isReviewRequired: true,
          },
        },
        {
          path: "mentor-info/:viewedMentorId",
          name: "mentor-info",
          component: MentorInfoDetail,
          meta: {
            isMentorAuthRequired: true,
            isReviewRequired: true,
          },
        },
        {
          path: "user-detail/:userId",
          name: "mentor-user-detail",
          component: MentorUserDetail,
          meta: {
            isMentorAuthRequired: true,
            isReviewRequired: true,
          },
        },
      ],
    },
    {
      path: "/user",
      component: UserRoot,
      children: [
        {
          path: "/",
          redirect: "top",
        },
        {
          path: "signup",
          name: userSignUpName,
          component: UserSignUp,
        },
        {
          path: "signin",
          name: userSignInName,
          component: UserSignIn,
        },
        {
          path: "top",
          name: "user-top",
          component: UserLandingPage,
        },
        {
          path: "home",
          name: "user-home",
          component: UserHome,
          meta: {
            isUserAuthRequired: true,
          },
        },
        {
          path: "profile",
          name: "user-profile",
          component: UserProfile,
          meta: {
            isUserAuthRequired: true,
          },
        },
        {
          path: "profile/edit",
          name: "user-profile-edit",
          component: UserProfileEdit,
          meta: {
            isUserAuthRequired: true,
          },
        },
        {
          path: "profile/first",
          name: "user-profile-first",
          component: UserProfileFirst,
          meta: {
            isUserAuthRequired: true,
          },
        },
        {
          path: "profile/second",
          name: "user-profile-second",
          component: UserProfileSecond,
          meta: {
            isUserAuthRequired: true,
          },
        },
        {
          path: "profile/third",
          name: "user-profile-third",
          component: UserProfileThird,
          meta: {
            isUserAuthRequired: true,
          },
        },
        {
          path: "thread",
          name: "user-thread",
          component: UserThread,
          meta: {
            isUserAuthRequired: true,
          },
        },
        {
          path: "thread/:mentorId",
          name: "user-message",
          component: UserMessage,
          meta: {
            isUserAuthRequired: true,
          },
        },
        {
          path: "search/mentor",
          name: "mentor-search",
          component: MentorSearch,
          meta: {
            isUserAuthRequired: true,
          },
        },
        {
          path: "search/mentor/:mentorId",
          name: "mentor-search-detail",
          component: MentorSearchDetail,
          meta: {
            isUserAuthRequired: true,
          },
        },
      ],
    },
    {
      path: "*",
      component: NotFoundComponent,
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

/**
 * メンター・ユーザー認証のチェック
 */
router.beforeEach(async (to, from, next) => {
  try {
    // 認証必須フラグの取得
    const isMentorAuthRequired = to.matched.some(
      (record) => record.meta.isMentorAuthRequired
    );
    const isReviewRequired = to.matched.some(
      (record) => record.meta.isReviewRequired
    );
    const isUserAuthRequired = to.matched.some(
      (record) => record.meta.isUserAuthRequired
    );

    if (isMentorAuthRequired || isUserAuthRequired) {
      // 認証必須の場合
      const authUser = await getAuthUser(to);
      to.params.authUser = authUser;
      if (!authUser) {
        // ログインしていない場合、ルートにリダイレクト
        return next({ path: "/" });
      } else {
        // ログインしている場合、どちらのユーザータイプか判断する
        if (isMentorAuthRequired) {
          // メンターのみアクセス可能な場合、メンターコレクションに存在するかチェック
          const mentorDS = await MentorRepository.getById(authUser.uid);
          if (mentorDS.exists()) {
            const mentor = new Mentor(mentorDS);
            if (mentor.id) {
              to.params.mentorId = mentor.id;
            }

            await MentorRepository.updateLastActiveAt(authUser.uid);

            if (isReviewRequired) {
              if (mentor.isReviewed) {
                return next();
              } else {
                return next({ name: "mentor-profile-first" });
              }
            }
            return next();
          } else {
            return next({ name: mentorSignInName });
          }
        } else if (isUserAuthRequired) {
          // ユーザーの認証が必要なパスの場合
          const userDS = await UserRepository.getById(authUser.uid);
          if (userDS.exists()) {
            const user = new User(userDS);
            if (user.id) {
              to.params.userId = user.id;
            }
            await UserRepository.updateLastActiveAt(authUser.uid);
            return next();
          } else {
            return next({ name: userSignInName });
          }
        }
      }
    }
    next();
  } catch (err) {
    console.error(err);
  }
});

export default router;
