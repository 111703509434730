const industries = [
  "食品・飲料",
  "繊維",
  "アパレル",
  "化粧品・医薬",
  "電機・自動車",
  "化学・石油",
  "住宅・建設",
  "不動産",
  "旅行",
  "ホテル・レジャー",
  "流通・小売り",
  "サービス",
  "商社",
  "人材・教育",
  "コンサルティング",
  "広告",
  "マスコミ",
  "出版・印刷",
  "IT",
  "ゲーム",
  "ソフトウェア",
  "銀行・信金",
  "クレジットカード",
  "証券",
  "保険",
  "インフラ",
  "鉄道・航空",
  "運輸・物流",
  "農林・水産",
  "官公庁",
] as const;

export default industries;
