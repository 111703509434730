import { auth } from "./firebase";

export default function getAuthUser(to: any): Promise<any> {
  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged((user) => {
      console.log("[onAuthStateChanged]");
      if (user) {
        resolve(user);
        return;
      }

      // 認証されていない場合はパスが要認証かによって遷移先を変更
      if (to.meta.isUserAuthRequired) {
        window.location.href = "/user/signin";
      } else {
        window.location.href = "/mentor/signin";
      }
      reject(new Error("error-not-authenticated"));
    });
  });
}
