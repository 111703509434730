














































































import Vue from "vue";
import MentorSearchDetailHeaderNavigation from "@/components/MentorSearchDetailHeaderNavigation.vue";
import MentorRepository from "@/repositories/mentor-repository";
import UserRepository from "@/repositories/user-repository";
import Mentor from "@/models/mentor";
import { auth } from "@/utils/firebase";

export default Vue.extend({
  components: {
    MentorSearchDetailHeaderNavigation,
  },
  data(): { mentor: Mentor; existsThread: boolean; isLoading: boolean } {
    return {
      mentor: new Mentor(),
      isLoading: true,
      /** すでに会話が存在しているか */
      existsThread: false,
    };
  },
  async mounted() {
    // メンターを取得
    await this.loadMentor();
    await this.checkExistsThread();
    this.isLoading = false;
  },
  methods: {
    async loadMentor() {
      const mentorId = this.$route.params.mentorId;
      const documentQuerySnapshot = await MentorRepository.getById(mentorId);
      if (!documentQuerySnapshot.exists()) {
        // 存在しない場合はエラー
        // TODO HOMEにとばす
        throw new Error();
      }
      this.mentor = new Mentor(documentQuerySnapshot);
    },
    /**
     * 該当のメンターとすでにメッセージやりとり済みかを返す
     */
    async checkExistsThread() {
      try {
        const dqs = await UserRepository.getMessageThreadByMentorId(
          auth.currentUser!.uid,
          this.mentor.id!
        );
        // dqsが空でなければすでにメッセージ済み
        this.existsThread = dqs.exists();
      } catch (err) {
        console.error(err);
      }
    },
    async startThread() {
      if (!this.existsThread) {
        // スレッドがまだ存在しないので作成する
        await UserRepository.startThread({
          userId: auth.currentUser!.uid,
          mentorId: this.mentor.id!,
        });
      }

      // すでにスレッドが存在するのでページ遷移を行う
      this.$router.push({
        name: "user-message",
        params: {
          mentorId: this.mentor.id!,
        },
      });
    },
  },
});
