









import Vue from "vue";
import SelectInput from "@/components/form/fields/selectInput.vue";
import selectOptionsType from "@/types/select-options";
import HRExperiences from "@/constants/hr-experiences";

/**
 * 採用経験選択フィールド
 */
export default Vue.extend({
  name: "HRExperienceInput",
  components: {
    SelectInput,
  },
  props: {
    originalValue: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "採用経験",
    },
  },
  data(): {
    HRExperiencesOptions: selectOptionsType;
  } {
    return {
      HRExperiencesOptions: HRExperiences.map((o) => ({ text: o, value: o })),
    };
  },
});
