























































































import Mentor from "@/models/mentor";
import Vue from "vue";

export default Vue.extend({
  props: {
    mentor: {
      type: Mentor,
    },
  },
  methods: {
    showDetail(mentor: Mentor) {
      const destination = this.$route.meta!.isMentorAuthRequired
        ? // メンターが閲覧している場合のメンター詳細
          "mentor-info"
        : // ユーザーが閲覧している場合のメンター詳細
          "mentor-search-detail";
      this.$router.push({
        name: destination,
        params: {
          mentorId: mentor.id!,
          viewedMentorId: mentor.id!,
        },
      });
    },
  },
});
