






















import Vue from "vue";
import MentorHeaderNavigation from "@/components/MentorHeaderNavigation.vue";
import MentorProfileCard from "@/components/MentorProfileCard.vue";
import InfiniteLoading from "@/components/InfiniteLoading.vue";
import LoadingAnimation from "@/components/LoadingAnimation.vue";
import MentorRepository from "@/repositories/mentor-repository";
import Mentor from "@/models/mentor";

export default Vue.extend({
  components: {
    MentorHeaderNavigation,
    MentorProfileCard,
    InfiniteLoading,
    LoadingAnimation,
  },
  data(): { mentors: Mentor[]; [key: string]: any } {
    return {
      isLoading: true,
      mentors: [],
      searchPriority: 10 ** 9,
    };
  },
  methods: {
    async infiniteHandler(e: any) {
      const $state = e.$state;
      try {
        // 順序を取得して並べ替え
        const qs = await MentorRepository.readMentorByPage(this.searchPriority);
        if (qs.empty) {
          // 新しいデータがとれなかった場合は無限スクロールを無効化する
          if (this.isLoading) {
            this.isLoading = false;
          }
          $state.complete();
          return;
        }

        // 取得したデータの中で一番優先度値が低いものを保持しておく。
        this.searchPriority = qs.docs.slice(-1)[0].data().searchPriority;
        const mentors = qs.docs.map((doc) => new Mentor(doc));
        // 既存と新規のメンターをあわせる。
        this.mentors = this.mentors.concat(mentors);
        // this.readProfileImages();
        this.isLoading = false;
        // infiniteHandlerを完了にする
        $state.loaded();
      } catch (err) {
        console.error(err);
      }
    },
  },
});
