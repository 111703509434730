import HRExperiences from "@/constants/hr-experiences";
import MentorRepository from "@/repositories/mentor-repository";
import mentorType from "@/types/mentor";
import { DocumentSnapshot } from "@firebase/firestore";

/**
 * メンターモデル
 */
export default class Mentor implements mentorType {
  id: string | null = null;
  lastActiveAt;
  name;
  university;
  profile;
  profileImageURL;
  createdAt;
  updatedAt;
  sex;
  birthday;
  email;
  consultations;
  isTermsOfServiceAccepted;
  prefecture;
  experiences: {
    idx: number;
    companyName: string;
    industry: string;
    jobTypes: string[];
    startYear: number;
    startMonth: number;
    endYear: number;
    endMonth: number;
    // 現在まで続いているか
    isPresent: boolean;
  }[] = [];
  isReviewed;
  searchPriority;
  industries;
  HRExperience: typeof HRExperiences[number] = HRExperiences[0];

  constructor(documentSnapshot: DocumentSnapshot | null = null) {
    this.id = null;
    if (!documentSnapshot) {
      return;
    }

    const data = documentSnapshot.data();
    if (!data) {
      return;
    }

    this.id = documentSnapshot.id;
    this.lastActiveAt = data.lastActiveAt
      ? data.lastActiveAt.toDate()
      : new Date();
    this.name = data.name;
    this.university = data.university;
    this.profile = data.profile;
    this.profileImageURL = data.profileImageURL;
    this.createdAt = data.createdAt ? data.createdAt.toDate() : new Date();
    this.updatedAt = data.updatedAt ? data.updatedAt.toDate() : new Date();
    this.sex = data.sex;
    this.birthday = data.birthday;
    this.email = data.email;
    this.consultations = data.consultations || ([] as string[]);
    this.isTermsOfServiceAccepted = data.isTermsOfServiceAccepted;
    this.prefecture = data.prefecture;
    this.experiences = data.experiences || ([] as any[]);
    this.isReviewed = data.isReviewed;
    this.searchPriority = data.searchPriority || 0;
    this.industries = data.industries || ([] as string[]);
    this.HRExperience = data.HRExperience as typeof HRExperiences[number];
  }

  /**
   * 現在のインスタンスの状態をfirestoreに反映
   */
  async update() {
    if (!this.id) {
      throw new Error("no mentor");
    }

    // 経験を時系列に並べ替える
    // 現在までの経験を最初に、その後は年月の比較をおこなう
    this.experiences = this.experiences.sort((a, b) =>
      a.isPresent
        ? -1
        : a.endYear >= b.endYear
        ? 1
        : a.endMonth >= b.endMonth
        ? 1
        : -1
    );
    console.log(this.experiences);

    // 検索用の業界の配列を整理する
    this.industries = this.experiences.map((e) => e.industry);

    await MentorRepository.updateFields(this.id, this.toJSON());
  }

  toJSON() {
    return {
      id: this.id,
      lastActiveAt: this.lastActiveAt,
      name: this.name,
      university: this.university,
      profile: this.profile,
      profileImageURL: this.profileImageURL,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      sex: this.sex,
      birthday: this.birthday,
      email: this.email,
      consultations: this.consultations || [],
      isTermsOfServiceAccepted: this.isTermsOfServiceAccepted,
      prefecture: this.prefecture,
      experiences: this.experiences || [],
      isReviewed: this.isReviewed || false,
      searchPriority: this.searchPriority,
      industries: this.industries || [],
      HRExperience: this.HRExperience,
    };
  }
}
