import { render, staticRenderFns } from "./MentorThread.vue?vue&type=template&id=394a8694&scoped=true&"
import script from "./MentorThread.vue?vue&type=script&lang=ts&"
export * from "./MentorThread.vue?vue&type=script&lang=ts&"
import style0 from "./MentorThread.vue?vue&type=style&index=0&id=394a8694&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "394a8694",
  null
  
)

export default component.exports