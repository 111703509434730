









import Vue from "vue";
import SelectInput from "@/components/form/fields/selectInput.vue";
import selectOptionsType from "@/types/select-options";
import prefectures from "@/constants/prefectures";

/**
 * 都道府県選択フィールド
 */
export default Vue.extend({
  name: "PrefectureInput",
  components: {
    SelectInput,
  },
  props: {
    originalValue: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "都道府県",
    },
  },
  data(): {
    options: selectOptionsType;
  } {
    return {
      options: prefectures.map((o) => ({ text: o, value: o })),
    };
  },
});
