







import Vue from "vue";
import InfiniteLoading from "vue-infinite-loading";

export default Vue.extend({
  components: {
    InfiniteLoading,
  },
  methods: {
    onInfinite($state: any) {
      this.$emit("onInfinite", { $state });
    },
  },
});
