const jobTypes = [
  "法人営業",
  "個人営業",
  "カスタマーサクセス",
  "営業企画",
  "商品企画",
  "経営企画",
  "マーケティング",
  "人事",
  "広報",
  "総務",
  "経理・財務",
  "エンジニア",
  "デザイナー",
  "コンサルタント",
  "技術・研究職",
  "教師・講師",
  "公務員",
  "社長",
  "役員",
] as const;

export default jobTypes;
