
















import Vue from "vue";

export default Vue.extend({
  name: "TextInput",
  components: {},
  props: {
    originalValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    maxLength: {
      type: Number,
      default: 20,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data(): {
    value: string;
  } {
    return {
      value: "",
    };
  },
  mounted() {
    this.value = this.originalValue;
  },
  methods: {
    onInput(e: any) {
      this.$emit("on-input", { value: this.value });
    },
  },
});
