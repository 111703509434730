




































import Vue from "vue";
import MentorNavigationContent from "@/components/MentorNavigationContent.vue";

export default Vue.extend({
  name: "MentorHeaderNavigation",
  components: {
    MentorNavigationContent,
  },
});
