<template>
  <header>
    <div class="header-container">
      <img
        width="24"
        height="24"
        alt="スレッド一覧に戻る"
        src="@/assets/image/icon/arrow.svg"
        @click="backToPreviousPage"
      />
      <div class="thread-header-container">
        <img
          width="*"
          height="48"
          alt="ジンジトーク"
          class="nav-item__logo"
          src="@/assets/image/logo.png"
          @click="$router.push({ name: 'user-home' })"
        />
      </div>
      <div class="header-icon-menu-wrapper__user">
        <a href="/user/thread" class="nav-icon-wrapper">
          <img
            width="20"
            height="20"
            alt="message"
            class="nav-item__message"
            src="@/assets/image/icon/message.svg"
          />
        </a>
        <a href="/user/search/mentor" class="nav-icon-wrapper">
          <img
            width="20"
            height="20"
            alt="search"
            src="@/assets/image/icon/search.svg"
          />
        </a>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "UserMessageHeaderNavigation",
  methods: {
    backToPreviousPage() {
      this.$router.push({ name: "mentor-search" });
    },
  },
};
</script>

<style scoped>
header {
  position: fixed;
  right: 0;
  left: 0;
  margin: auto;
  top: 0;
  width: 100%;
  height: 60px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  background: #fff;
  z-index: 10;
}
.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  padding: 16px;
  box-sizing: border-box;
}
.nav-checkbox {
  display: none;
}
#nav-drawer {
  position: relative;
}
#nav-open {
  display: inline-block;
  width: 30px;
  height: 22px;
  vertical-align: middle;
}
#nav-open span,
#nav-open span:before,
#nav-open span:after {
  position: absolute;
  height: 3px;
  width: 24px;
  border-radius: 3px;
  background: #555;
  display: block;
  content: "";
  cursor: pointer;
}
#nav-open span:before {
  bottom: -8px;
}
#nav-open span:after {
  bottom: -16px;
}
#nav-close {
  display: none;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0;
  transition: 0.3s ease-in-out;
}
#nav-content {
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  max-width: 320px;
  height: 100%;
  background: #fff;
  transition: 0.3s ease-in-out;
  -webkit-transform: translateX(-105%);
  transform: translateX(-105%);
}
#nav-input:checked ~ #nav-close {
  display: block;
  opacity: 0.5;
}
#nav-input:checked ~ #nav-content {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  box-shadow: 6px 0 25px rgba(0, 0, 0, 0.15);
}
.nav-item__logo {
  height: 32px;
  margin-left: 16px;
  display: block;
}
.nav-icon-wrapper {
  display: block;
  text-align: right;
}
.header-icon-menu-wrapper__user {
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
}
.nav-item__message {
  padding-right: 16px;
}
</style>
