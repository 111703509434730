

























































import Vue from "vue";
import { auth, db, serverTimestamp } from "../utils/firebase";
import LoadingAnimation from "@/components/LoadingAnimation.vue";
import { signInWithEmailAndPassword } from "firebase/auth";
import MentorRepository from "@/repositories/mentor-repository";

export default Vue.extend({
  components: {
    LoadingAnimation,
  },
  data() {
    return {
      email: "",
      password: "",
      errorMessage: "",
      isSigningIn: false,
      isLoading: true,
    };
  },
  async mounted() {
    this.isLoading = false;
  },
  methods: {
    // singin処理
    async signIn() {
      try {
        this.isSigningIn = true;
        const result = await signInWithEmailAndPassword(
          auth,
          this.email,
          this.password
        );
        const authUser = result.user;
        const dqs = await MentorRepository.getById(authUser.uid);
        if (dqs.exists()) {
          if (dqs.data().isReviewed) {
            this.$router.push({ name: "mentor-home" });
            return;
          }
        }
        this.$router.push({ name: "mentor-profile-first" });
      } catch (error: any) {
        this.isSigningIn = false;
        console.error(error);
        if (error.code === "auth/invalid-email") {
          this.errorMessage = "正しいメールアドレスを入力してください";
        } else if (error.code === "auth/wrong-password") {
          this.errorMessage = "パスワードが違います";
        } else {
          this.errorMessage = "ログインが失敗しました";
        }
      }
    },
  },
});
