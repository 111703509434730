import { render, staticRenderFns } from "./MentorMessage.vue?vue&type=template&id=4c3c8337&scoped=true&"
import script from "./MentorMessage.vue?vue&type=script&lang=ts&"
export * from "./MentorMessage.vue?vue&type=script&lang=ts&"
import style0 from "./MentorMessage.vue?vue&type=style&index=0&id=4c3c8337&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c3c8337",
  null
  
)

export default component.exports