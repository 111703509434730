import MessageThread from "@/models/message-thread";
import { db } from "@/utils/firebase";
import {
  onSnapshot,
  query,
  addDoc,
  collection,
  orderBy,
  limit,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  where,
  setDoc,
  serverTimestamp,
  writeBatch,
} from "firebase/firestore";
import MentorRepository from "./mentor-repository";

export default class UserRepository {
  static collectionName = "users";
  static messageThreads = "messageThreads";
  static messages = "messages";

  static async getById(id: string) {
    const ref = doc(db, this.collectionName, id);
    return await getDoc(ref);
  }

  static async updateLastActiveAt(id: string) {
    const ref = doc(db, this.collectionName, id);
    return await updateDoc(ref, {
      lastActiveAt: new Date(),
    });
  }

  static async getByIds(ids: string[]) {
    const q = query(
      collection(db, UserRepository.collectionName),
      where("id", "in", ids),
      limit(500)
    );
    return await getDocs(q);
  }

  /**
   * フィールドを指定してdbを更新
   * @param userId
   * @param data
   */
  static async updateFields(userId: string, data: any) {
    // 更新時間を更新
    data.updatedAt = serverTimestamp();
    const ref = doc(db, UserRepository.collectionName, userId);
    await updateDoc(ref, data);
  }

  /**
   *
   */
  static async getMessageThreads(userId: string) {
    const q = query(
      collection(
        db,
        UserRepository.collectionName,
        userId,
        UserRepository.messageThreads
      ),
      orderBy("updatedAt", "desc")
    );
    return await getDocs(q);
  }

  /**
   * ユーザーのメッセージスレッドの中から与えられたメンターIDと一致するスレッドを取得
   */
  static async getMessageThreadByMentorId(userId: string, mentorId: string) {
    const docRef = doc(
      db,
      UserRepository.collectionName,
      userId,
      UserRepository.messageThreads,
      mentorId
    );
    return await getDoc(docRef);
  }

  static async getMessageMentors() {
    return true;
    //   const messageMentorsDQS = await db
    //   .collection("users")
    //   .doc(this.$route.params.authUser.uid)
    //   .collection("messageMentors")
    //   .doc(this.$route.params.mentorId)
    //   .get();
    // this.existsThread = messageMentorsDQS.exists;
  }

  static async createUser(email: string, userId: string) {
    await setDoc(doc(db, UserRepository.collectionName, userId), {
      email,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  }

  /**
   * ユーザーのコレクション内にスレッドを作成
   * メンター側はfunctionsで書き込み
   */
  static async startThread({
    userId,
    mentorId,
  }: {
    userId: string;
    mentorId: string;
  }) {
    const userMessageThreadDocRef = doc(
      db,
      UserRepository.collectionName,
      userId,
      UserRepository.messageThreads,
      mentorId
    );

    const batch = writeBatch(db);

    const userThreadData = new MessageThread();
    userThreadData.id = mentorId;
    userThreadData.userId = userId;
    userThreadData.mentorId = mentorId;
    userThreadData.lastActiveAt = serverTimestamp();
    userThreadData.createdAt = serverTimestamp();
    userThreadData.updatedAt = serverTimestamp();
    batch.set(userMessageThreadDocRef, userThreadData.toJSON());

    await batch.commit();
  }

  /**
   *
   * @param userId
   * @param mentorId
   * @returns
   */
  static async getMessagesByMentorId(userId: string, mentorId: string) {
    const messageThreadCollectionRef = collection(
      db,
      UserRepository.collectionName,
      userId,
      UserRepository.messageThreads,
      mentorId,
      UserRepository.messages
    );
    const q = query(
      messageThreadCollectionRef,
      orderBy("createdAt", "asc"),
      limit(500)
    );
    return await getDocs(q);
  }

  /**
   *
   * @param userId
   * @param mentorId
   * @param content
   * @returns
   */
  static async sendMessage(userId: string, mentorId: string, content: string) {
    const messageThreadCollectionRef = collection(
      db,
      UserRepository.collectionName,
      userId,
      UserRepository.messageThreads,
      mentorId,
      UserRepository.messages
    );
    const data = {
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      content,
      senderId: userId,
    };
    return await addDoc(messageThreadCollectionRef, data);
  }

  /**
   *
   */
  static async subscribeToThread({
    userId,
    mentorId,
    callback,
  }: {
    userId: string;
    mentorId: string;
    callback: any;
  }) {
    const q = query(
      collection(db, "users", userId, "messageThreads", mentorId, "messages"),
      orderBy("createdAt", "desc"),
      limit(1)
    );

    return onSnapshot(q, (qs) => {
      callback(qs);
    });
  }
}
