
























































































































import Vue from "vue";
import { auth } from "../utils/firebase";
import MentorHeaderNavigation from "@/components/MentorHeaderNavigation.vue";
import SaveButtonBlue from "@/components/SaveButtonBlue.vue";
import ImageInput from "@/components/form/fields/imageInput.vue";
import PrefectureInput from "@/components/form/fields/PrefectureInput.vue";
import ExperienceInput from "@/components/form/fields/ExperienceInput.vue";
import ConsultationsType from "@/components/form/fields/ConsultationInput.vue";
import HRExperienceInput from "@/components/form/fields/HRExperienceInput.vue";
import MentorRepository from "@/repositories/mentor-repository";
import Mentor from "@/models/mentor";
import StorageHandler from "@/utils/storage";

export default Vue.extend({
  components: {
    MentorHeaderNavigation,
    SaveButtonBlue,
    ImageInput,
    PrefectureInput,
    ExperienceInput,
    ConsultationsType,
    HRExperienceInput,
  },
  data(): {
    mentor: Mentor;
    isUpdating: any;
    wrongFileTypeMessage: any;
    isUpdateFailed: any;
    file: any;
    errorMessages: string[];
  } {
    return {
      mentor: new Mentor(),
      isUpdating: false,
      wrongFileTypeMessage: "",
      isUpdateFailed: false,
      file: null,
      errorMessages: [],
    };
  },
  async mounted() {
    await this.loadProfile();
  },
  methods: {
    async loadProfile() {
      const mentorId = auth.currentUser!.uid;

      // Userとユーザー情報を取得
      const dqs = await MentorRepository.getById(mentorId);
      if (!dqs.exists()) {
        return;
      }
      this.mentor = new Mentor(dqs);
    },
    async updateProfile() {
      this.isUpdating = true;
      this.errorMessages = [];

      if (!this.isProfileDataValid()) {
        this.showUpdateFailedMessage();
        this.isUpdating = false;
        return;
      }

      try {
        if (this.file) {
          // storageに顔写真をアップロードし、URLを取得
          const url = await StorageHandler.upload(
            `mentors/${auth.currentUser!.uid}/profile`,
            this.file
          );

          // 顔写真のURLを保存するオブジェクトにもたせる
          this.mentor.profileImageURL = url;
        }

        await this.mentor.update();

        this.$router.push({ name: "mentor-profile" });
      } catch (error) {
        console.error("Error writing document: ", error);
        this.showUpdateFailedMessage();
      }

      this.isUpdating = false;
    },
    /**
     * エラーメッセージの表示
     */
    showUpdateFailedMessage() {
      this.isUpdateFailed = true;
      setTimeout(() => {
        this.isUpdateFailed = false;
      }, 3000);
    },
    /**
     * 入力値のバリデーション
     */
    isProfileDataValid() {
      let isValid = true;
      if (this.mentor.experiences.length === 0) {
        this.errorMessages.push("経験は1つ以上入力してください");
        isValid = false;
      }

      this.mentor.experiences.forEach((experience, idx) => {
        if (
          !(
            experience.companyName &&
            experience.industry &&
            experience.jobTypes.length >= 1 &&
            experience.startYear &&
            experience.startMonth &&
            ((experience.endYear && experience.endMonth) ||
              experience.isPresent)
          )
        ) {
          this.errorMessages.push(
            `${idx + 1}番目の経験の内容を修正してください。`
          );
          isValid = false;
        }
      });

      if (!(this.file || this.mentor.profileImageURL)) {
        this.errorMessages.push("プロフィール画像を入力してください。");
        isValid = false;
      }

      if (!this.mentor.name) {
        this.errorMessages.push("名前を入力してください。");
        isValid = false;
      }
      if (!this.mentor.university) {
        this.errorMessages.push("出身大学を入力してください。");
        isValid = false;
      }
      if (!this.mentor.prefecture) {
        this.errorMessages.push("所在地を入力してください。");
        isValid = false;
      }
      if (!this.mentor.profile) {
        this.errorMessages.push("プロフィールを入力してください。");
        isValid = false;
      }

      return isValid;
    },
    /**
     *
     */
    onUploadProfileImage($event: any) {
      console.log($event);
      this.file = $event.imageData;
    },
    /**
     *
     */
    addExperienceForm() {
      this.mentor.experiences.push({
        idx: new Date().getTime(),
        companyName: "",
        industry: "",
        jobTypes: [],
        startYear: 2021,
        startMonth: 1,
        endYear: 2021,
        endMonth: 1,
        isPresent: false,
      });
    },
    /**
     * 指定されたインデックスの経験を削除
     */
    removeExperienceForm(idx: number) {
      this.mentor.experiences = this.mentor.experiences.filter(
        (_: any, _idx: number) => _idx !== idx
      );
    },
  },
});
