


































































import User from "@/models/user";
import UserRepository from "@/repositories/user-repository";
import Vue from "vue";
import { auth } from "../utils/firebase";

export default Vue.extend({
  name: "UserNavigationContent",
  data(): {
    bgImageSrc: string;
    user: User;
    errorMessage: string;
  } {
    return {
      bgImageSrc: require("@/assets/image/nav_bg.png"),
      user: new User(),
      errorMessage: "",
    };
  },
  async mounted() {
    this.user = new User(await UserRepository.getById(auth.currentUser!.uid));
  },
  methods: {
    async signOut() {
      try {
        // signoutしたらonAuthStateChangeで遷移
        await auth.signOut();
      } catch (error) {
        console.log(error);
        this.errorMessage = "ログアウトに失敗しました。";
      }
    },
  },
});
