



































import Vue from "vue";
import SelectInput from "@/components/form/fields/selectInput.vue";
import selectOptionsType from "@/types/select-options";
import industries from "@/constants/industries";
import CompanyInput from "@/components/form/fields/CompanyInput.vue";
import IndustryInput from "@/components/form/fields/IndustryInput.vue";
import JobTypesInput from "@/components/form/fields/JobTypesInput.vue";
import PeriodInput from "@/components/form/fields/PeriodInput.vue";

/**
 * 経験入力フィールド
 */
export default Vue.extend({
  name: "ExperienceInput",
  components: {
    SelectInput,
    CompanyInput,
    IndustryInput,
    JobTypesInput,
    PeriodInput,
  },
  props: {
    originalValue: {
      type: String,
      default: "",
    },
    experience: {
      // type: Object,
      default: {
        companyName: "",
        industry: "",
        jobTypes: [],
        startYear: 2021,
        startMonth: 1,
        endYear: 2021,
        endMonth: 1,
        isPresent: false,
      },
    },
  },
  data(): {
    industryOptions: selectOptionsType;
  } {
    return {
      industryOptions: industries.map((o) => ({ text: o, value: o })),
    };
  },
});
