















































import Vue from "vue";
import { storage } from "../utils/firebase";
import User from "@/models/user";
import { ref, getDownloadURL } from "firebase/storage";
import MentorHeaderNavigation from "@/components/MentorHeaderNavigation.vue";
import UserRepository from "@/repositories/user-repository";

export default Vue.extend({
  name: "MentorUserDetail",
  components: {
    MentorHeaderNavigation,
  },
  data: (): {
    user: User | null;
    preview: string;
    isUpdating: boolean;
    successMessage: string;
  } => ({
    user: null,
    isUpdating: false,
    successMessage: "",
    preview: "",
  }),
  async mounted() {
    await this.loadProfile();
  },
  methods: {
    async loadProfile() {
      // Userがログイン状態の場合、firestoreからデータを取得
      const userDQS = await UserRepository.getById(this.$route.params.userId);

      const fileRef = ref(
        storage,
        `users/${this.$route.params.userId}/images/efilopr`
      );
      let url = "";
      try {
        url = await getDownloadURL(fileRef);
      } catch (error) {
        // switch (error.code) {
        //   case "storage/object-not-found":
        this.preview = "";
        //     break;
        // }
      }

      if (userDQS.exists()) {
        this.user = new User(userDQS);
        this.preview = url;
      }
    },
  },
});
