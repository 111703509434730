
















































































import Vue from "vue";
import MentorHeaderNavigation from "@/components/MentorHeaderNavigation.vue";
import MentorRepository from "@/repositories/mentor-repository";
import Mentor from "@/models/mentor";
import { auth } from "@/utils/firebase";

export default Vue.extend({
  name: "MentorProfile",
  components: {
    MentorHeaderNavigation,
  },
  data(): {
    mentor: Mentor;
    isUpdating: any;
    successMessage: any;
  } {
    return {
      mentor: new Mentor(),
      isUpdating: false,
      successMessage: "",
    };
  },
  async mounted() {
    await this.loadProfile();
  },
  methods: {
    async loadProfile() {
      // Userがログイン状態の場合、firestoreからデータを取得
      const ds = await MentorRepository.getById(auth.currentUser!.uid);

      if (!ds.exists()) {
        // TODO エラー処理
        return;
      }
      this.mentor = new Mentor(ds);
    },
    async updateProfile() {
      this.isUpdating = true;
      try {
        await this.mentor.update();

        this.showSuccessMessage("ユーザー情報を更新しました。");
      } catch (error) {
        console.error("Error writing document: ", error);
      }
      this.isUpdating = false;
    },

    /**
     *
     */
    showSuccessMessage(message: string) {
      this.successMessage = message;
      setTimeout(() => {
        this.successMessage = "";
      }, 3000);
    },
  },
});
