
















































































import Vue from "vue";
import User from "@/models/user";
import UserHeaderNavigation from "@/components/UserHeaderNavigation.vue";
import UserRepository from "@/repositories/user-repository";
import { auth } from "@/utils/firebase";

export default Vue.extend({
  name: "UserProfile",
  components: {
    UserHeaderNavigation,
  },
  data(): { user: User } {
    return {
      user: new User(),
    };
  },
  async mounted() {
    await this.loadProfile();
  },
  methods: {
    async loadProfile() {
      const userId = auth.currentUser!.uid;

      // Userとユーザー情報を取得
      const dqs = await UserRepository.getById(userId);
      if (!dqs.exists()) {
        return;
      }
      this.user = new User(dqs);
    },
  },
});
