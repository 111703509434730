




































































import selectOptionsType from "@/types/select-options";
import Vue from "vue";
import ChebkboxInput from "@/components/form/fields/CheckboxInput.vue";

/**
 * 期間入力フィールド
 */
export default Vue.extend({
  name: "PeriodInput",
  components: {
    ChebkboxInput,
  },
  props: {
    originalStartYear: {
      type: Number,
      default: 2010,
    },
    originalStartMonth: {
      type: Number,
      default: 1,
    },
    originalEndYear: {
      type: Number,
      default: 2010,
    },
    originalEndMonth: {
      type: Number,
      default: 1,
    },
    originalIsPresent: {
      type: Boolean,
      default: false,
    },
  },
  data(): {
    startYear: number;
    startMonth: number;
    endYear: number;
    endMonth: number;
    yearOptions: selectOptionsType;
    monthOptions: selectOptionsType;
    isPresent: boolean;
    isReady: boolean;
  } {
    return {
      startYear: 2010,
      startMonth: 1,
      endYear: 2010,
      endMonth: 1,
      isPresent: false,
      yearOptions: [...Array(33)].map((_, idx) => ({
        text: `${1990 + idx}`,
        value: 1990 + idx,
      })),
      monthOptions: [...Array(12)].map((_, idx) => ({
        text: `${1 + idx}`,
        value: 1 + idx,
      })),
      isReady: false,
    };
  },
  mounted() {
    this.startYear = this.originalStartYear;
    this.startMonth = this.originalStartMonth;
    this.endYear = this.originalEndYear;
    this.endMonth = this.originalEndMonth;
    this.isPresent = this.originalIsPresent;
    this.isReady = true;
  },
  methods: {
    onChange() {
      this.$emit("on-change", {
        startYear: this.startYear,
        startMonth: this.startMonth,
        endYear: this.endYear,
        endMonth: this.endMonth,
        isPresent: this.isPresent,
      });
    },
  },
});
