




















































































import Vue from "vue";
import { auth } from "../utils/firebase";
import UserHeaderNavigation from "@/components/UserHeaderNavigation.vue";
import SaveButtonBlue from "@/components/SaveButtonBlue.vue";
import UniversityInput from "@/components/form/fields/UniversityInput.vue";
import SchoolYearInput from "@/components/form/fields/SchoolYearInput.vue";
import BunriClassificationInput from "@/components/form/fields/BunriClassificationInput.vue";
import IndustriesInput from "@/components/form/fields/IndustriesInput.vue";
import JobTypesInput from "@/components/form/fields/JobTypesInput.vue";
import CompanyTypesInput from "@/components/form/fields/CompanyTypesInput.vue";
import ConsultationsInput from "@/components/form/fields/ConsultationInput.vue";
import UserRepository from "@/repositories/user-repository";
import User from "@/models/user";

export default Vue.extend({
  components: {
    UserHeaderNavigation,
    SaveButtonBlue,
    UniversityInput,
    SchoolYearInput,
    BunriClassificationInput,
    IndustriesInput,
    JobTypesInput,
    CompanyTypesInput,
    ConsultationsInput,
  },
  data(): {
    user: User;
    isUpdating: boolean;
    errorMessages: string[];
    [key: string]: any;
  } {
    return {
      user: new User(),
      isUpdating: false,
      successMessage: "",
      isUpdateFailed: false,
      errorMessages: [],
    };
  },
  async mounted() {
    await this.loadProfile();
  },
  methods: {
    async loadProfile() {
      const userId = auth.currentUser!.uid;

      // Userとユーザー情報を取得
      const dqs = await UserRepository.getById(userId);
      if (!dqs.exists()) {
        return;
      }
      this.user = new User(dqs);
    },
    async updateProfile() {
      // 保存中状態にする
      this.isUpdating = true;

      // validation
      if (!this.isProfileDataValid()) {
        this.showUpdateFailedMessage();
        this.isUpdating = false;
        return;
      }

      try {
        // プロフィール入力済みにする
        this.user.isProfileCompleted = true;
        // firestoreに保存
        await this.user.update();
        this.$router.push({ name: "user-profile" });
      } catch (error: any) {
        console.error(error);
        this.showUpdateFailedMessage();
      }
      this.isUpdating = false;
    },
    // 失敗時にメッセージ表示
    showUpdateFailedMessage() {
      this.isUpdateFailed = true;
      setTimeout(() => {
        this.isUpdateFailed = false;
      }, 3000);
    },
    /**
     *
     */
    isProfileDataValid() {
      let isValid = true;

      if (!this.user.name) {
        this.errorMessages.push("名前を入力してください。");
        isValid = false;
      }
      if (!this.user.university) {
        this.errorMessages.push("大学を入力してください。");
        isValid = false;
      }
      if (!this.user.schoolYear) {
        this.errorMessages.push("学年を入力してください。");
        isValid = false;
      }
      if (!this.user.bunriClassification) {
        this.errorMessages.push("文理区分を入力してください。");
        isValid = false;
      }
      if (this.user.interestedIndustries.length === 0) {
        this.errorMessages.push("興味のある業界を入力してください。");
        isValid = false;
      }
      if (this.user.interestedJobTypes.length === 0) {
        this.errorMessages.push("興味のある職種を入力してください。");
        isValid = false;
      }
      if (this.user.interestedCompanyTypes.length === 0) {
        this.errorMessages.push("興味のある企業の属性を入力してください。");
        isValid = false;
      }
      if (!this.user.consultations) {
        this.errorMessages.push("相談したいことを入力してください。");
        isValid = false;
      }

      return isValid;
    },

    showSuccessMessage(message: string) {
      this.successMessage = message;
      setTimeout(() => {
        this.successMessage = "";
      }, 3000);
    },
  },
});
