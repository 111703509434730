


































































import Vue from "vue";
import { auth } from "../utils/firebase";
import MentorMessageHeaderNavigation from "@/components/MentorMessageHeaderNavigation.vue";
import Message from "@/models/message";
import MessageThread from "@/models/message-thread";
import UserRepository from "@/repositories/user-repository";
import { formatDateByToday } from "@/utils/dateUtils";
import MentorRepository from "@/repositories/mentor-repository";
import User from "@/models/user";

export default Vue.extend({
  name: "MentorMessage",
  components: {
    MentorMessageHeaderNavigation,
  },
  data(): {
    messages: Message[];
    draft: string;
    user: User;
    thread: MessageThread;
    isUserLoaded: boolean;
    lastMessage: any;
    messageListener: any;
    isReady: boolean;
  } {
    return {
      messages: [],
      draft: "",
      user: new User(),
      thread: new MessageThread(),
      isUserLoaded: false,
      lastMessage: null,
      messageListener: null,
      isReady: false,
    };
  },
  async mounted() {
    await this.loadThread();
    await this.loadUser();
    await this.subscribeToThread();

    // ユーザーを取得
    await this.loadMessages();

    // 一番下までスクロール
    this.scrollIntoLastMessage();
  },
  destroyed() {
    if (this.messageListener) {
      // リスナーをunmountしておく
      this.messageListener();
    }
  },
  methods: {
    /** 一番下までスクロール */
    scrollIntoLastMessage() {
      this.lastMessage = this.$refs.lastMessage;
      if (this.lastMessage) {
        this.lastMessage.scrollIntoView();
      }
    },
    async loadThread() {
      const dqs = await MentorRepository.getMessageThreadByUserId(
        this.$route.params.threadId,
        auth.currentUser!.uid
      );
      this.thread = new MessageThread(dqs);
    },
    /**
     *
     */
    async loadMessages() {
      const messagesQS = await MentorRepository.getMessagesByUserId(
        this.$route.params.threadId,
        auth.currentUser!.uid
      );
      this.messages = messagesQS.docs.map((doc) => new Message(doc));
      this.isReady = true;
    },
    async loadUser() {
      const dqs = await UserRepository.getById(this.thread.userId);
      if (!dqs.exists()) {
        throw Error("no mentor");
      }

      this.user = new User(dqs);
      this.isUserLoaded = true;
    },
    async subscribeToThread() {
      this.messageListener = await MentorRepository.subscribeToThread({
        userId: this.user.id!,
        mentorId: auth.currentUser!.uid,
        callback: (value: any) => {
          if (!this.isReady) {
            // 初回は何もしない
            return;
          }

          this.messages = value.docs.reduce((output: Message[], doc: any) => {
            output.push(new Message(doc));
            return output;
          }, this.messages);

          this.scrollIntoLastMessage();
        },
      });
    },
    async sendMessage() {
      await MentorRepository.sendMessage(
        auth.currentUser!.uid,
        this.user.id!,
        this.draft.trim()
      );

      this.draft = "";

      await this.loadMessages();

      this.scrollIntoLastMessage();
    },
    formatDate(date: any) {
      return formatDateByToday(date);
    },
  },
});
