import messageThreadType from "@/types/message-thread";
import { DocumentSnapshot } from "@firebase/firestore";

/**
 * メッセージをやり取りするスレッドのモデル
 */
export default class MessageThread implements messageThreadType {
  id: string | null = null;
  userId;
  mentorId;
  createdAt;
  updatedAt;
  lastActiveAt;
  lastMessage;

  constructor(documentSnapshot: DocumentSnapshot | null = null) {
    this.id = null;
    if (!documentSnapshot) {
      return;
    }

    const data = documentSnapshot.data();
    if (!data) {
      return;
    }

    this.id = data.id;
    this.lastActiveAt = data.lastActiveAt
      ? data.lastActiveAt.toDate()
      : new Date();
    this.userId = data.userId;
    this.mentorId = data.mentorId;
    this.createdAt = data.createdAt ? data.createdAt.toDate() : new Date();
    this.updatedAt = data.updatedAt ? data.updatedAt.toDate() : new Date();
    this.lastMessage = data.lastMessage;
  }

  toJSON() {
    return {
      id: this.id,
      userId: this.userId,
      mentorId: this.mentorId,
      lastActiveAt: this.lastActiveAt,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      lastMessage: this.lastMessage || "",
    };
  }
}
