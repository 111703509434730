










import Vue from "vue";
import SelectInput from "@/components/form/fields/selectInput.vue";
import selectOptionsType from "@/types/select-options";
import industries from "@/constants/industries";

/**
 * 業界選択フィールド
 */
export default Vue.extend({
  name: "IndustryInput",
  components: {
    SelectInput,
  },
  props: {
    originalValue: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "業界",
    },
    hint: {
      type: String,
      default: "業界",
    },
  },
  data(): {
    industryOptions: selectOptionsType;
  } {
    return {
      industryOptions: industries.map((o) => ({ text: o, value: o })),
    };
  },
});
