import UserRepository from "@/repositories/user-repository";
import userType from "@/types/user";
import { DocumentSnapshot } from "@firebase/firestore";

/**
 * メンターモデル
 */
export default class User implements userType {
  id: string | null = null;
  lastActiveAt;
  name;
  university;
  profileImageURL;
  createdAt;
  updatedAt;
  sex;
  phoneNumber;
  birthday;
  schoolYear;
  bunriClassification;
  email;
  interestedIndustries;
  interestedJobTypes;
  interestedCompanyTypes;
  consultations;
  isTermsOfServiceAccepted;
  isProfileCompleted;

  constructor(documentSnapshot: DocumentSnapshot | null = null) {
    this.id = null;
    if (!documentSnapshot) {
      return;
    }

    const data = documentSnapshot.data();
    if (!data) {
      return;
    }

    this.id = documentSnapshot.id;
    this.lastActiveAt = data.lastActiveAt
      ? data.lastActiveAt.toDate()
      : new Date();
    this.name = data.name;
    this.university = data.university;
    this.profileImageURL = data.profileImageURL;
    this.createdAt = data.createdAt ? data.createdAt.toDate() : new Date();
    this.updatedAt = data.updatedAt ? data.updatedAt.toDate() : new Date();
    this.sex = data.sex;
    this.phoneNumber = data.phoneNumber;
    this.birthday = data.birthday;
    this.schoolYear = data.schoolYear;
    this.bunriClassification = data.bunriClassification;
    this.email = data.email;
    this.interestedIndustries = data.interestedIndustries || ([] as string[]);
    this.interestedJobTypes = data.interestedJobTypes || ([] as string[]);
    this.interestedCompanyTypes =
      data.interestedCompanyTypes || ([] as string[]);
    this.consultations = data.consultations || ([] as string[]);
    this.isTermsOfServiceAccepted = data.isTermsOfServiceAccepted;
    this.isProfileCompleted = data.isProfileCompleted;
  }

  /**
   * 現在のインスタンスの状態をfirestoreに反映
   */
  async update() {
    if (!this.id) {
      throw new Error("no user");
    }

    await UserRepository.updateFields(this.id, this.toJSON());
  }

  toJSON() {
    return {
      id: this.id,
      lastActiveAt: this.lastActiveAt,
      name: this.name,
      university: this.university,
      profileImageURL: this.profileImageURL,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      sex: this.sex,
      phoneNumber: this.phoneNumber,
      birthday: this.birthday,
      schoolYear: this.schoolYear,
      bunriClassification: this.bunriClassification,
      email: this.email,
      interestedIndustries: this.interestedIndustries,
      interestedJobTypes: this.interestedJobTypes,
      interestedCompanyTypes: this.interestedCompanyTypes,
      consultations: this.consultations,
      isTermsOfServiceAccepted: this.isTermsOfServiceAccepted,
      isProfileCompleted: this.isProfileCompleted,
    };
  }
}
