























































































import Vue from "vue";

import { auth, serverTimestamp } from "../utils/firebase";
import LoadingAnimation from "@/components/LoadingAnimation.vue";
import MentorRepository from "@/repositories/mentor-repository";
import { createUserWithEmailAndPassword } from "firebase/auth";

export default Vue.extend({
  components: {
    LoadingAnimation,
  },
  data() {
    return {
      email: "",
      password: "",
      errorMessage: "",
      isSigningUp: false,
      isLoading: true,
    };
  },
  async mounted() {
    this.isLoading = false;
  },
  methods: {
    async signUp() {
      try {
        this.isSigningUp = true;

        const authResult = await createUserWithEmailAndPassword(
          auth,
          this.email,
          this.password
        );

        const authUser = authResult.user;

        if (!authUser.email) {
          throw new Error("no user email");
        }

        await MentorRepository.createMentor(authUser.email, authUser.uid);
        this.$router.push({ name: "mentor-profile-first" });
      } catch (error: any) {
        this.isSigningUp = false;
        console.log(error);

        if (error.code === "auth/invalid-email") {
          this.errorMessage = "正しいメールアドレスを入力してください";
        } else if (error.code === "auth/email-already-in-use") {
          this.errorMessage = "メールアドレスは既に登録されています";
        } else if (error.code === "auth/weak-password") {
          this.errorMessage = "パスワードは6文字以上にしてください";
        } else {
          this.errorMessage = "サインアップに失敗しました";
        }
      }
    },
  },
});
