















import Vue from "vue";
import industries from "@/constants/industries";

/**
 * 業界複数選択フィールド
 */
export default Vue.extend({
  name: "IndustriesInput",
  props: {
    originalValue: {
      type: Array,
      default: () => [],
    },
  },
  data(): {
    industries: typeof industries;
    values: string[];
  } {
    return {
      values: [],
      industries,
    };
  },
  mounted() {
    this.values = this.originalValue as string[];
  },
});
