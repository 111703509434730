const bunriClassifications = {
  bunkei: {
    text: "文系",
    value: "文系",
  },
  rikei: {
    text: "理系",
    value: "理系",
  },
} as const;

export type bunriClassificationValues =
  typeof bunriClassifications[keyof typeof bunriClassifications]["value"];

export default bunriClassifications;
