

































import Vue from "vue";
import { auth } from "@/utils/firebase";
import { sendPasswordResetEmail } from "firebase/auth";

export default Vue.extend({
  name: "sendPasswordResetEmail",
  data() {
    return {
      errorMessage: "",
      emailForSendPasswordReset: "",
    };
  },
  methods: {
    async _sendPasswordResetEmail() {
      try {
        await sendPasswordResetEmail(auth, this.emailForSendPasswordReset);
        alert("メールを送信しました");
        this.$router.push("/user/signin");
      } catch (error) {
        console.log(error);
        this.errorMessage = "送信に失敗しました";
      }
    },
  },
});
