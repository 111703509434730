























import Vue, { PropType } from "vue";

/**
 * 選択ボックス
 */
export default Vue.extend({
  name: "SelectInput",
  props: {
    originalValue: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    options: {
      type: Array as PropType<{ text: string; value: any }[]>,
      default: [],
    },
  },
  data(): {
    value: string;
  } {
    return {
      value: "",
    };
  },
  mounted() {
    this.value = this.originalValue;
  },
  methods: {
    onInput(e: any) {
      this.$emit("on-input", { value: this.value });
    },
  },
});
