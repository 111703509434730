















import Vue from "vue";
import SelectInput from "@/components/form/fields/selectInput.vue";
import CheckboxInput from "@/components/form/fields/CheckboxInput.vue";
import jobTypes from "@/constants/job-types";

/**
 * 職種選択フィールド
 */
export default Vue.extend({
  name: "JobTypesInput",
  components: {
    SelectInput,
    CheckboxInput,
  },
  props: {
    originalValue: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "職種を選択",
    },
  },
  data(): {
    jobTypes: typeof jobTypes;
    values: string[];
  } {
    return {
      values: [],
      jobTypes,
    };
  },
  mounted() {
    this.values = this.originalValue as string[];
  },
});
