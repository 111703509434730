
























































































import Vue from "vue";
import { auth } from "../utils/firebase";
import UserSignOut from "@/components/UserSignOut.vue";
import SaveButtonBlue from "@/components/SaveButtonBlue.vue";
import UserRepository from "@/repositories/user-repository";
import User from "@/models/user";
import TextInput from "@/components/form/fields/textInput.vue";
import sexOptions from "@/constants/sex-options";
import selectOptionsType from "@/types/select-options";
import SelectInput from "@/components/form/fields/selectInput.vue";
import YearSelect from "@/components/form/fields/YearSelect.vue";
import UniversityInput from "@/components/form/fields/UniversityInput.vue";
import SchoolYearInput from "@/components/form/fields/SchoolYearInput.vue";
import BunriClassificationInput from "@/components/form/fields/BunriClassificationInput.vue";
import EmailInput from "@/components/form/fields/EmailInput.vue";

/**
 * ユーザープロフィールステップ1
 */
export default Vue.extend({
  name: "UserProfileFirst",
  components: {
    UserSignOut,
    SaveButtonBlue,
    TextInput,
    SelectInput,
    YearSelect,
    UniversityInput,
    SchoolYearInput,
    BunriClassificationInput,
    EmailInput,
  },

  data(): {
    user: User;
    isUpdating: boolean;
    isUpdateFailed: boolean;
    isLoadingCompleted: boolean;
    sexOptions: selectOptionsType;
  } {
    return {
      user: new User(),
      isUpdating: false,
      isUpdateFailed: false,
      isLoadingCompleted: false,
      // 配列を選択肢の型に変換
      sexOptions: sexOptions.map((o) => ({ text: o, value: o })),
    };
  },
  async mounted() {
    // 途中離脱の場合のためにすでに保存済みのプロフィールをロードする。
    await this.loadProfile();
    this.isLoadingCompleted = true;
  },
  methods: {
    /**
     * 途中離脱の場合すでに保存済みのプロフィールをロードする。
     */
    async loadProfile() {
      const userId = auth.currentUser!.uid;

      // Userとユーザー情報を取得
      const dqs = await UserRepository.getById(userId);
      if (!dqs.exists()) {
        return;
      }
      this.user = new User(dqs);
    },
    /**
     * プロフィールの更新処理
     */
    async updateProfile() {
      // validation
      if (!this.isProfileDataValid()) {
        this.showUpdateFailedMessage();
        return;
      }

      // 保存中状態にする
      this.isUpdating = true;

      try {
        // firestoreに保存
        await this.user.update();
      } catch (error: any) {
        console.error(error);
        this.showUpdateFailedMessage();
        return;
      }

      this.$router.push({ name: "user-profile-second" });
    },
    /**
     * 入力されたプロフィール項目が正しいか判定。
     * 名前とプロフィールが存在するかの確認をする。
     */
    isProfileDataValid(): boolean {
      const existsName = !!this.user.name;
      return existsName;
    },
    /**
     * 保存が失敗した際のメッセージを表示する。
     */
    showUpdateFailedMessage(): void {
      this.isUpdateFailed = true;
      setTimeout(() => {
        this.isUpdateFailed = false;
      }, 3000);
    },
  },
});
