export const formatDate = (date: any) => {
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const dateStr = ("0" + date.getDate()).slice(-2);
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  return `${month}-${dateStr} ${hours}:${minutes}`;
};

export const formatDateYYMM = (date: any) => {
  if (!(date instanceof Date)) {
    return "-";
  }
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  return `${year}-${month}`;
};

export const formatDateByToday = (date: any) => {
  // 本日のものか判定
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth() + 1;
  const currentDate = now.getDate();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const _date = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  if (currentYear <= year && currentMonth <= month && currentDate <= _date) {
    // 本日のスレッドなので、時刻を返す
    return `${("0" + hour).slice(-2)}:${("0" + minute).slice(-2)}`;
  } else {
    // 本日以前のスレッドなので、日付を返す
    return `${("0" + month).slice(-2)}/${("0" + _date).slice(-2)}`;
  }
};
